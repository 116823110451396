import React from 'react';
import { Link } from 'react-router-dom'
import SubLayout from '../../components/Layout/SubLayout'
import strings from "../../components/Language";
import "./wallet.scss"
import _ from 'lodash';

import NoDataFound from './../../assets/images/icons/no_data_found.svg';

import walletdeposit from './../../assets/images/icons/wallet-deposit.webp';
import walletwithdraw from './../../assets/images/icons/wallet-withdraw.webp';
import wallettransfer from './../../assets/images/icons/wallet-transfer.webp';
import walletcoin from './../../assets/images/icons/wallet-coin.svg';

import { Button } from 'antd';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../components/config/api.js';
import { getToken, storeUserData, getUserData } from '../../components/Util';

import ListItem from '@mui/material/ListItem';
class GasHistoryScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			data: [],
			nextPageLink: "",
			prevPageLink: "",
			balance: 0,
			user_data: {},
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			this.setState({ access_token: token });
			setTimeout(() => {
				var user_data = getUserData();
				this.setState({ user_data: (user_data && user_data.data) ? user_data.data : {} });
				this.getData(api.gas_history);
			});
		}
	}

	getData(API) {
		if (!API) {
			toast.error(strings.URI_not_found);
			return false;
		}
		var self = this;
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {

			if (ress.data.data && ress.data.data.length > 0) {
				self.setState({
					data: ress.data.data,
					nextPageLink: ress.data.links.next,
					prevPageLink: ress.data.links.prev,
					balance: ress.data.available_gas
				});
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	getNextData(API) {
		if (!API) {
			toast.error(strings.URI_not_found);
			return false;
		}
		var self = this;
		self.setState({
			nextPageLink: "",
			prevPageLink: "",
		});
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {

			if (ress.data.data && ress.data.data.length > 0) {
				self.setState({
					data: [...self.state.data, ...ress.data.data],
					nextPageLink: ress.data.links.next,
					prevPageLink: ress.data.links.prev,
				});
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			toast.error(e.message);
		});
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.gas_fees }}>
				<div className='offset-md-4 col-md-4' style={{ paddingInline: 5 }}>
					<div style={{ marginTop: 10 }}>
						<div className='flex-center list_back_active' style={{ justifyContent: "space-between", padding: 10, borderRadius: 10 }}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={walletcoin} alt="Api1" width={60} height={60}></img>
								<div style={{ marginLeft: 10 }}>
									<div style={{}}>
										<span style={{ fontSize: 12, color: "#000", borderWidth: "bold" }}>{strings.gas_fees}</span>
									</div>
									<div style={{}}>
										<span style={{ fontSize: 18 }}>{this.state.balance.toFixed(8)}</span>
									</div>
									<div style={{}}>
										<span style={{ fontSize: 14 }}>= {this.state.balance.toFixed(8)} {strings.USD}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style={{ marginTop: 20 }}>
						<div className='flex-center' style={{
							justifyContent: "space-between", padding: 10, borderRadius: 5
						}}>
							<div className='text-center'>
								<span style={{}}>{strings.history_record}</span>
							</div>
						</div>
					</div>
					<div style={{ marginTop: 0 }}>
						{this.state.data && this.state.data.length > 0 ?
							<>
								{
									this.state.data.map((item, i) =>
										<ListItem disablePadding key={i}>
											<Link to={{
												// pathname: `/binding/${item.id}`,
												data: item,
												params: item.id
											}} params={{ data: item }}
												style={{ marginTop: 10, display: "inline-block", width: "100%" }}>
												<div className={`api_list_back_wallet`}>
													<div style={{ padding: 10, borderRadius: 5, background: "#07051f" }}>
														<div className={`flex-center`} style={{
															justifyContent: "space-between"
														}}>
															<div className='col-7' style={{ flexDirection: "row", paddingRight: 5 }}>
																<div style={{ marginLeft: 10 }}>
																	<span style={{ fontWeight: "bold" }}>{item.amount.toFixed(8)}</span>
																</div>
															</div>
															<div className='col-5 flex-center' style={{ flexDirection: "row", fontSize: 12, justifyContent: "flex-end" }}>
																{item.created_at_}
															</div>
														</div>
														<div style={{ marginLeft: 10, overflowWrap: "anywhere" }}>
															<span style={{ fontWeight: "bold", fontSize: 12 }}>{item.description}</span>
														</div>
													</div>
												</div>
											</Link>
										</ListItem>
									)
								}
								{this.state.nextPageLink ?
									<div className='flex-center' style={{ justifyContent: "center" }}>
										<Button onClick={() => this.getNextData(api.nextPageLink)} type="primary" className='btn rounded-sm btn-m text-uppercase font-700 mt-4 mb-3 btn-full '
											style={{ fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF" }}>
											{strings.Load_More}
										</Button>
									</div>
									: null}
							</>
							: <div className='text-center' style={{ padding: 20, borderRadius: 5 }}>
								<img src={NoDataFound} alt="Api2"></img>
							</div>}
					</div>
				</div>
			</SubLayout >
		);
	}
}

export default GasHistoryScreen;

import React from 'react'
import { withRouter } from 'react-router-dom'
import './Layout.scss';

import BackArrow from './../../assets/images/icons/back_arrow.svg';
import rewardbg from './../../assets/images/other/reward_bg.png';
class SubLayoutReward extends React.Component {
  constructor(props) {
    super(props);
    var headerData = (this.props && this.props.data) ? this.props.data : {};
    var headerTitle = (this.props && this.props.data && this.props.data.title) ? this.props.data.title : "";
    this.state = {
      headerData: headerData,
      headerTitle: headerTitle,
    }
  }
  render() {
    const { history } = this.props
    return (
      <>
        <div id="page" className='back_reward' style={{ backgroundImage: `url(${rewardbg})` }}>
          <div className="header-bar header-fixed header-app main_header">
            {/* <Link to={"/"} className=""> */}
            <div onClick={history.goBack} className="" style={{ cursor: "pointer", marginLeft: 20, width: 25 }}>
              <img src={BackArrow} alt="" width={10} />
            </div>
            {/* </Link> */}
            <div className="header-title color-theme text-center">{(this.state.headerTitle) ? this.state.headerTitle : "..."}</div>
            <div className="" style={{ marginRight: 20, width: 25 }}>
            </div>
          </div>
          <div
            className="page-content page-content-sub-layout header-clear"
            style={{
              marginTop: 10,
              overflowY: "scroll",
            }}
          >
            {this.props.children}
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(SubLayoutReward)

import React from 'react'
import { withRouter } from 'react-router-dom'
import './Layout.scss';

import BackArrow from './../../assets/images/icons/back_arrow.svg';
class SubLayout extends React.Component {
  constructor(props) {
    super(props);
    var headerData = (this.props && this.props.data) ? this.props.data : {};
    var headerTitle = (this.props && this.props.data && this.props.data.title) ? this.props.data.title : "";
    var is_pair_log = (this.props && this.props.data && this.props.data.is_pair_log) ? this.props.data.is_pair_log : false;
    var pair_logo = (this.props && this.props.data && this.props.data.pair_logo) ? this.props.data.pair_logo : "";
    this.state = {
      headerData: headerData,
      headerTitle: headerTitle,
      is_pair_log: is_pair_log,
      pair_logo: pair_logo,
    }
  }
  render() {
    const { history } = this.props
    return (
      <>
        <div id="page">
          <div className="header-bar header-fixed header-app main_header">
            {/* <Link to={"/"} className=""> */}
            <div onClick={history.goBack} className="" style={{ cursor: "pointer", marginLeft: 20, width: 25 }}>
              <img src={BackArrow} alt="" width={10} />
            </div>
            {/* </Link> */}
            {this.state.is_pair_log ?
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBlock: 15, marginRight: 20, margin: "0 auto" }}>
                {this.state.pair_logo ?
                  <img className="image_icon" src={this.state.pair_logo} alt="Bitcoin" width={25} height={25} />
                  : null}
                <div className='width-auto margin-left-1'>
                  <span className=''>{(this.state.headerTitle) ? this.state.headerTitle : "..."}</span>
                </div>
              </div>
              :
              <div className="header-title color-theme text-center">{(this.state.headerTitle) ? this.state.headerTitle : "..."}</div>
            }
            <div className="" style={{ marginRight: 20, width: 25 }}>
            </div>
          </div>
          <div
            className="page-content page-content-sub-layout header-clear"
            style={{
              marginTop: 10,
              overflowY: "scroll",
            }}
          >
            {this.props.children}
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(SubLayout)

import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from "../../components/Language";

import {Button } from 'antd';
import _ from 'lodash';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';

class AddWalletScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            wallet_address: "",
            wallet_label: "",
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
            });
        }
    }

    sendData() {
        var self = this;
        if (self.state.access_token) {
            if (!self.state.wallet_address) {
                toast.error(strings.the_wallet_address_field_is_required);
                return false;
            }

            var json = {
                wallet_address: self.state.wallet_address,
                wallet_label: self.state.wallet_label
            }

            self.setState({ loading: true });
            axios({
                method: 'POST',
                url: api.wallet_address_store,
                data: json,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    toast.success(ress.data.message);
                    self.props.history.replace({ pathname: `/withdraw-address` })
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
                self.setState({ loading: false });
            }).catch(function (e) {
                //toast.error(e.message);
                self.setState({ loading: false });
            });
        }
    };

    render() {
        return (
            <SubLayout data={{ "title": strings.Add_Wallet }}>
                <div className="list_item" style={{ padding: 0, paddingBlock: 5 }}>
                    <div className='row' style={{ margin: 5 }}>
                        <div className='col-8' style={{ marginTop: 0 }}>
                            <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.Currency}</label>
                        </div>
                        <div className='col-4' style={{ marginTop: 0, textAlign: "right" }}>
                            <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.usdt}</label>
                        </div>
                    </div>
                </div>
                <div className="list_item" style={{ padding: 0, paddingBlock: 5 }}>
                    <div className='row' style={{ margin: 5 }}>
                        <div className='col-12' style={{ marginTop: 0 }}>
                            <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.Mainnet}</label>
                        </div>
                    </div>
                </div>
                <div className="list_item" style={{ padding: 0, paddingBlock: 5 }}>
                    <div className='row' style={{ margin: 5, marginTop: 5 }}>
                        <div className='col-12' style={{ marginTop: 0 }}>
                            <label className='' style={{ color: "#FFFFFF", fontSize: 14, marginRight: 15 }}>{strings.Wallet_Address}</label>
                            <div className="form-custom form-label form-icon mb-3">
                                <input
                                    name='wallet_address'
                                    onChange={e => this.setState({ wallet_address: e.target.value })}
                                    value={this.state.wallet_address}
                                    className="form-control rounded-xs"
                                    placeholder="Please Enter Address"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ margin: 5, marginTop: 5 }}>
                        <div className='col-12' style={{ marginTop: 0 }}>
                            <label className='' style={{ color: "#FFFFFF", fontSize: 14, marginRight: 15 }}>{strings.Wallet_Label}</label>
                            <div className="form-custom form-label form-icon mb-3">
                                <input
                                    name='wallet_label'
                                    onChange={e => this.setState({ wallet_label: e.target.value })}
                                    value={this.state.wallet_label}
                                    className="form-control rounded-xs"
                                    placeholder="Optional"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ padding: 0, paddingBlock: 5, textAlign: "-webkit-center" }}>
                    <Button
                        onClick={() => this.sendData()}
                        loading={this.state.loading}
                        type="primary"
                        htmlType="submit"
                        className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full'
                        style={{ opacity: this.state.loading ? 0.7 : 1, fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: (window.innerWidth - 20), borderRadius: 10 }}>
                        {this.state.loading ? strings.loading : strings.confirm}
                    </Button>
                </div>

            </SubLayout >
        );
    }
}

export default AddWalletScreen;
import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from "../../components/Language";

import "./tradbot.scss";

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../components/config/api.js';
import { getToken, storeUserData } from '../../components/Util';
import _ from 'lodash';

import $ from 'jquery';

import NoDataFound from './../../assets/images/icons/no_data_found.svg';

import Sell from './../../assets/images/icons/sell_white.svg';

class OrderListBot2Screen extends React.Component {
    constructor(props) {
        super(props);
        var params_id = this.props.match.params.id;
        this.state = {
            params_id: params_id,
            access_token: "",
            botLiveData: [],
            data: [],
            pair: {},
            parinames: [],
            is_load: true,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            });
        }
    }

    getData() {
        var self = this;
        if (self.state.access_token) {
            self.setState({ data: [], pariNames: [], pair: {} });
            axios({
                method: 'GET',
                url: api.botb + this.state.params_id + "/bot-orders",
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {

                if (ress.data && ress.data.error === 200) {
                    // self.setState({ data: ress.data.data });

                    // var parinames = "";

                    var parinames = [];
                    var botData = _.filter(ress.data.data, function (o, key) {
                        o.average_ = o.bot.total_usdt_qty / o.bot.total_coin_qty;
                        o.is_calculate = (o.bot.total_coin_qty > 0) ? true : false;

                        if (o.bot.pair.name) {
                            parinames.push(`"` + o.bot.pair.name + `"`);
                        }

                        return o;
                    });

                    var pair = (ress.data.data && ress.data.data[0] && ress.data.data[0].bot && ress.data.data[0].bot.pair) ? ress.data.data[0].bot.pair : {};

                    self.setState({ data: botData, pariNames: _.uniq(parinames), pair: pair });

                    if (parinames && parinames.length != 0) {
                        self.getLiveData();
                        setInterval(() => {
                            self.getLiveData();
                        }, 10000);
                    }

                } else {
                }
                self.setState({ is_load: false });
            }).catch(function (e) {
                self.setState({ is_load: false });
                //toast.error(e.message);
            });
        }
    };

    getLiveData() {
        var self = this;
        if (self.state.access_token) {

            // var API = encodeURI("https://api.binance.com/api/v3/ticker/24hr?symbols=" + self.state.pariNames);
            // var API = "https://api.binance.com/api/v3/ticker/24hr?symbols=[" + _.toString(self.state.pariNames) + "]";
            var API = "https://price.benchmarkbot.io/price?symbol=" + this.state.pair.name;
            axios({
                method: 'GET',
                // url: api.pair_report,
                url: API,
            }).then(function (ress) {
                // if (ress && ress.status == 200) {
                if (ress && ress.data.error == 200) {
                    var data = _.filter(self.state.data, function (o) {
                        // var finalLiveData = _.find(ress.data.data, { symbol: o.bot.pair.name });
                        var finalLiveData = ress.data.data;
                        if (finalLiveData) {

                            // Get Live data
                            o.liveData = finalLiveData;

                            // Ge percentage diff
                            var per = ((finalLiveData.lastPrice - o.data.price) / o.data.price);
                            o.per = (per) * 100;

                            // Get Floting profit diff
                            var X = (finalLiveData.lastPrice - o.data.price);
                            var Y = (X / o.data.price);
                            o.floating_profit = (Y * o.data.cost);
                        }
                        return o;
                    })
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                // toast.error(value[0]);
                            }
                        });
                    } else {
                        // toast.error(ress.data.message);
                    }
                }
                self.setState({ is_load: false });
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    sellOrder(id) {
        var self = this;

        if (!id) {
            toast.error(strings.id_not_found);
            return false;
        }

        if (self.state.access_token) {
            axios({
                method: 'POST',
                url: api.botb + id + "/sell-order",
                data: {},
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    toast.success(ress.data.message);
                    self.setState({ data: [], pariNames: [], pair: {} });
                    setTimeout(() => {
                        self.getData();
                    }, 2500);
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    sellAlert(id, txt) {
        var self = this;
        $.confirm({
            title: strings.confirm + '!',
            theme: 'dark',
            content: strings.are_you_sure_you + " " + txt + " " + strings.order_,
            buttons: {
                somethingElse: {
                    text: strings.SELL,
                    btnClass: 'btn-success',
                    action: function () {
                        self.sellOrder(id);
                    }
                },
                cancel: function () {
                    //close
                },
            }
        });
    };

    render() {

        return (
            <>
                {!this.state.is_load ?
                    <SubLayout data={{ "title": (this.state.pair && this.state.pair.name) ? this.state.pair.name : '', "pair_logo": (this.state.pair && this.state.pair.icon_url) ? this.state.pair.icon_url : '', is_pair_log: true }}>
                        <div
                            className='offset-md-4 col-md-4 common_textbox'
                            style={{ marginInline: 5, marginTop: 20, margin: "0 auto" }}
                        >
                            {this.state.data && this.state.data.length != 0 ?
                                <>
                                    <div className="list_item" style={{ padding: 0 }}>
                                        {this.state.data.map((item, i) =>
                                            <>
                                                {i != 0 ? <div className='boder_' style={{ margin: 0 }} /> : null}

                                                <div key={i + 101} className='row' style={{ margin: 0, paddingBlock: 10, paddingInline: 10, alignItems: "center", flexDirection: "row" }}>
                                                    <div className="col-2" style={{ textAlign: "center" }}>
                                                        <div className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{i}</div>
                                                        <div className='gray_color' style={{ fontSize: 12 }}>{strings.layer}</div>
                                                    </div>
                                                    <div className='col-2' style={{ padding: 2, textAlign: "center" }}>
                                                        <div className='' style={{ color: "#FFFFFF", fontSize: 12 }}>
                                                            {(item.data && item.data.price) ? Number.parseFloat(item.data.price).toFixed(4) : "0.00"}
                                                        </div>
                                                        <div className='gray_color' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.price}</div>
                                                    </div>
                                                    <div className='col-6' style={{ padding: 2, textAlign: "center" }}>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <div className='gray_color' style={{ color: "#FFFFFF", fontSize: 12, marginRight: 5 }}>
                                                                {"PnL (%):"}
                                                            </div>
                                                            <div className='avg_label'>
                                                                <div className='width-fit-content avg_price'>
                                                                    <label style={{ fontSize: 12, paddingBlock: 2, paddingInline: 5 }} className={(item.per && item.per > 0) ? "text_up" : "text_down"}>
                                                                        {(item.per && item.per > 0) ? Number.parseFloat(item.per ?? 0).toFixed(2) : "0"}%
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='gray_color' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.floating_profit}: {Number.parseFloat(item.floating_profit ?? 0).toFixed(4)}</div>
                                                    </div>
                                                    <div className='main_content col-2' style={{ padding: 2, textAlign: "-webkit-right" }}>
                                                        {(item.floating_profit && item.floating_profit >= 0) ?
                                                            <div onClick={() => this.sellAlert(item._id, i + " " + strings.layer)} className='col-4 text-center sell_btn_order_'>
                                                                <img src={Sell} width={12} alt="" height={12} />
                                                                <label className='' style={{ color: "#FFFFFF", fontSize: 12, marginLeft: 3 }}>{strings.SELL}</label>
                                                            </div>
                                                            :
                                                            <div className='col-4 text-center sell_btn_order_' style={{ background: "#808080" }}>
                                                                <img src={Sell} width={12} alt="" height={12} />
                                                                <label className='' style={{ color: "#FFFFFF", fontSize: 12, marginLeft: 3 }}>{strings.SELL}</label>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                                :
                                <div className="text-center" style={{ marginTop: 25 }}>
                                    <img src={NoDataFound} alt="Api2"></img>
                                </div>
                            }
                        </div>
                    </SubLayout >
                    : null}
            </>
        );
    }
}

export default OrderListBot2Screen;
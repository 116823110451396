import React from 'react';
// import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import SubLayout from '../../components/Layout/SubLayout'
import strings from '../../components/Language';
import "./profile.scss"

import ArrowForword from './../../assets/images/icons/arrow_forword.svg';

import AccountProblem from './../../assets/images/icons/account_problem.svg';
import Trend from './../../assets/images/icons/trend.svg';
import Bulb from './../../assets/images/icons/bulb.svg';
import ReportViolation from './../../assets/images/icons/report_violation.svg';
import MoneyFlow from './../../assets/images/icons/money_flow.svg';
import ApiBind from './../../assets/images/icons/api_bind.svg';
import Bug from './../../assets/images/icons/bug.svg';
import More from './../../assets/images/icons/more.svg';

class HelpLineScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
		}

	}

	comingSoon() {
		toast.warning(strings.Coming_Soon);
	}

	render() {
		return (
			<SubLayout data={{ "title": strings.helpline }}>
				<div className="profile">
					<div style={{ marginTop: 10 }}>
						<div onClick={() => this.comingSoon()} className='flex-center' style={{
							cursor: "pointer",
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={AccountProblem} alt="MemberCenter" width={25} height={25}></img>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.account_problem}</span>
								</div>
							</div>
							<div>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
						<div onClick={() => this.comingSoon()} className='flex-center' style={{
							cursor: "pointer",
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={Trend} alt="Wallet" width={25} height={25}></img>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.trading_problem}</span>
								</div>
							</div>
							<div>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
						<div onClick={() => this.comingSoon()} className='flex-center' style={{
							cursor: "pointer",
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={Bulb} alt="ApiBind" width={25} height={25}></img>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.suggestion_opinions}</span>
								</div>
							</div>
							<div>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
						<div onClick={() => this.comingSoon()} className='flex-center' style={{
							cursor: "pointer",
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={ReportViolation} alt="Team" width={25} height={25}></img>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.report_violation}</span>
								</div>
							</div>
							<div>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
						<div onClick={() => this.comingSoon()} className='flex-center' style={{
							cursor: "pointer",
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={MoneyFlow} alt="RewardDetails" width={25} height={25}></img>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.money_flow_problem}</span>
								</div>
							</div>
							<div>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
						<div onClick={() => this.comingSoon()} className='flex-center' style={{
							cursor: "pointer",
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={ApiBind} alt="ShareCopy" width={25} height={25}></img>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.api_binding_issues}</span>
								</div>
							</div>
							<div>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
						<div onClick={() => this.comingSoon()} className='flex-center' style={{
							cursor: "pointer",
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={Bug} alt="ShareCopy" width={25} height={25}></img>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.bug_problem}</span>
								</div>
							</div>
							<div>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
						<div onClick={() => this.comingSoon()} className='flex-center' style={{
							cursor: "pointer",
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<img src={More} alt="ShareCopy" width={25} height={25}></img>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.other}</span>
								</div>
							</div>
							<div>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</div>
					</div>
				</div>
			</SubLayout >
		);
	}
}

export default HelpLineScreen;


import React from 'react';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import strings from '../../components/Language';

import _ from 'lodash';
import moment from 'moment';
import SubLayout from '../../components/Layout/SubLayout';

class OrdersBScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			data: [],
			next_page_url: "",
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
				this.getData();
			});
		}
	}

	getData() {
		var self = this;
		if (self.state.access_token) {
			axios({
				method: 'GET',
				url: api.order_report_b,
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {

					self.setState({
						data: (ress.data.data && ress.data.data.data) ? ress.data.data.data : [],
						next_page_url: (ress.data.data && ress.data.data.next_page_url) ? ress.data.data.next_page_url : [],
					});
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {

						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.logs}}>
				{
					this.state.data.length > 0 ?
						null
						:
						<h5 className='text-center text-danger mt-2'>{strings.no_logs}</h5>
				}
				{this.state.data.map((item, i) =>
					<div key={i + 100} className="list_item">
						<div className="row">
							<div className='col-6'>
								<div className='width-auto margin-left-1'>
									<span className=''>{(item.data && item.data.symbol) ? item.data.symbol : ''}</span>
								</div>
							</div>
							<div className='col-6 avg_label'>
								<div className='width-auto'>
									<label className=''>{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</label>
								</div>
							</div>
						</div>
						<div className="row">
							<div className='col-6'>
								<div className='width-auto margin-left-1'>
									<span className={(item.data && item.data.side && item.data.side == 'sell') ? 'text_down' : 'text_up'} style={{ textTransform: "uppercase" }}>{strings.market}{"/"}{(item.data && item.data.side) ? item.data.side : '-'}</span>
								</div>
							</div>
							<div className='col-6 avg_label'>
								<div className='width-auto'>
									<span className='text_up'>{(item.data && item.data.status) ? item.data.status : '-'}</span>
								</div>
							</div>
						</div>
						<div className="row list_details">
							<div className='col-6 gray_color'>
								<div className='width-auto margin-left-1'>
									<span className=''>{strings.amount}</span>
								</div>
							</div>
							<div className='col-6 avg_label'>
								<div className='width-auto'>
									<span className=''>{(item.data && item.data.amount) ? Number.parseFloat(item.data.amount).toFixed(5) : '0.00'}</span>
								</div>
							</div>
						</div>
						<div className="row list_details">
							<div className='col-6 gray_color'>
								<div className='width-auto margin-left-1'>
									<span className=''>{strings.price}</span>
								</div>
							</div>
							<div className='col-6 avg_label'>
								<div className='width-auto'>
									<span className=''>{(item.data && item.data.price) ? Number.parseFloat(item.data.price).toFixed(5) : '0.00'}</span>
								</div>
							</div>
						</div>
						<div className="row list_details">
							<div className='col-6 gray_color'>
								<div className='width-auto margin-left-1'>
									<span className=''>{strings.fee}{' '} {(item.data && item.data.fee && item.data.fee.currency) ? "(" + item.data.fee.currency + ")" : ''}</span>
								</div>
							</div>
							<div className='col-6 avg_label'>
								<div className='width-auto'>
									<span className=''>{(item.data && item.data.fee && item.data.fee.cost) ? Number.parseFloat(item.data.fee.cost).toFixed(10) : '0.00'}</span>
								</div>
							</div>
						</div>
						<div className="row list_details">
							<div className='col-6 gray_color'>
								<div className='width-auto margin-left-1'>
									<span className=''>{strings.total}{' '}</span>
								</div>
							</div>
							<div className='col-6 avg_label'>
								<div className='width-auto'>
									<span className=''>{(item.data && item.data.cost) ? Number.parseFloat(item.data.cost).toFixed(10) : '0.00'}</span>
								</div>
							</div>
						</div>
					</div>
				)}
			</SubLayout>
		);
	}
}
export default OrdersBScreen;

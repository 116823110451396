import React from 'react';
import { toast } from 'react-toastify';
import SubLayout from '../../components/Layout/SubLayout'
import strings from '../../components/Language';
import "./profile.scss"

import DeleteError from './../../assets/images/icons/delete_error.svg';

class DeleteAccountScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
		}
		this.handleChange = this.handleChange.bind(this);

	}

	handleChange(event, newValue) {
		const self = this;
		self.setState({ tabValue: newValue });
	};

	comingSoon() {
		toast.warning(strings.Coming_Soon);
	}

	render() {
		return (
			<SubLayout data={{ "title": strings.delete_account }}>
				<div className="profile">
					<div style={{ marginTop: 50 }}>

						<div className="col text-center">
							<img className="preload-img image-logo rounded-xs entered loaded" alt="" src={DeleteError} />

							<div style={{ marginBlock: 20 }}>{strings.delete_account_note}</div>

							<div style={{ marginTop: 30, }}>
								<div style={{ marginBottom: 10, color: "#FC8383" }}>{strings.delete_account_following_conditions}</div>
								<div style={{}}>
									<div>{strings.delete_account_following_conditions_1}</div>
									<div>{strings.delete_account_following_conditions_2}</div>
									<div>{strings.delete_account_following_conditions_3}</div>
									<div>{strings.delete_account_following_conditions_4}</div>
								</div>
							</div>
						</div>

						<div onClick={() => this.comingSoon()} className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full '
							style={{ fontSize: 14, paddingBlock: 10, background: '#446CE3', borderRadius: 10 }}
						>{strings.confirm}</div>
					</div>
				</div>
			</SubLayout >
		);
	}
}

export default DeleteAccountScreen;

import React from 'react'
class LayoutAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <>
          <div
            className="page-content mb-0 pb-0"
          >
            {this.props.children}
          </div>
      </>
    );
  }
}
export default LayoutAuth

import React from 'react'
import { Link } from 'react-router-dom'
import strings from '../../components/Language';

//  CSS
import './../../assets/styles/style.scss';
import './register.scss';
import RegisterSuccessful from './../../assets/images/icons/register_successful.svg';

class RegisterSuccessfulScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <div className="card card-style mb-0 bg-transparent shadow-0 bg-3 mx-0 rounded-0 main_height auth_page" data-card-height="cover"
                    style={{ marginTop: 30 }}
                >
                    <div className="card-center">
                        <div className="card card-style">
                            <div className="content" style={{ margin: 20 }}>
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ fontSize: 26, fontWeight: "bold", marginBottom: 10 }}>{strings.congratulations}!</div>
                                    <div style={{ fontSize: 14, fontWeight: "bold", marginBottom: 10 }}>{strings.register_successful}</div>
                                </div>

                                <div style={{ textAlign: "center" }} >
                                    <img src={RegisterSuccessful} alt="" className="register_successful_img"/>
                                </div>

                                <Link to="/login" className='btn rounded-sm btn-m text-uppercase font-700 mt-4 mb-3 btn-full '
                                    style={{ fontSize: 14, paddingBlock: 10, background: '#5CBC7C' }}
                                >{strings.login_now}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>


        );
    }
}

export default RegisterSuccessfulScreen;

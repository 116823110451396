
import React from 'react';
import { Link } from 'react-router-dom'
import SubLayout from '../../components/Layout/SubLayout';
import strings from "./../../components/Language";

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';
class AnnouncementScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			data: [],
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
				this.getData();
			});
		}
	}

	getData() {
		var self = this;
		if (self.state.access_token) {
			axios({
				method: 'GET',
				url: api.announcement,
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					self.setState({ data: ress.data.data });
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.Latest_Announcement }}>
				{this.state.data.map((item, i) =>
					<div key={i + 101} className="list_item">
						<Link to={{ pathname: `/announcement-detail/${item.id}` }}>
							<div>{item.subject}</div>
							<div>{item.created_at_}</div>
						</Link>
					</div>
				)}
			</SubLayout>
		);
	}
}
export default AnnouncementScreen;

import React from 'react';
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import strings from '../../components/Language';
import "./profile.scss"
import _ from 'lodash';

import Avatar1 from './../../assets/images/icons/avatar1.svg';
import ArrowForword from './../../assets/images/icons/arrow_forword.svg';

import MemberCenter from './../../assets/images/profile/crown.png';
import Wallet from './../../assets/images/profile/wallet.png';
import ApiBind from './../../assets/images/profile/link.png';
import Team from './../../assets/images/profile/group.png';
import RewardDetails from './../../assets/images/profile/money-bag.png';
import ShareCopy from './../../assets/images/profile/share.png';
import HelpLine from './../../assets/images/profile/customer-care.png';
import SecurityCenter from './../../assets/images/profile/lock.png';
import Settings from './../../assets/images/profile/setting.png';
import notifications from './../../assets/images/profile/bell.png';
import Copy from './../../assets/images/icons/copy.png';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
class ProfileScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			user_data: {},
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			this.setState({ access_token: token });
			setTimeout(() => {
				this.getData();
			});
		}
	}

	getData() {
		var self = this;

		axios({
			method: 'GET',
			url: api.profile_show,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				console.log(ress.data.data);
				self.setState({ user_data: ress.data.data });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	// redirectToChat() {
	// 	<Route path='/privacy-policy' component={() => {
	// 		window.location.href = 'https://example.com/1234';
	// 		return null;
	// 	}} />
	// }

	copyText(text) {
		navigator.clipboard.writeText(text);
		toast.success(strings.copy_id);
	}

	render() {
		return (
			<Layout data={{ "title": strings.profile }}>
				<div className="profile">
					<div className="" style={{ borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid" }}>
						<div className="flex-center" style={{ justifyContent: "space-between", padding: 10 }}>
							<div className="flex-center">
								<Link to={"/profile-information"} className='profile_back' style={{}} >
									<img className='profile_back' src={(this.state.user_data && this.state.user_data.avatar_url) ? this.state.user_data.avatar_url : Avatar1} alt=""></img>
								</Link>
								<div style={{ marginLeft: 10 }}>
									<Link to={"/profile-information"}><span style={{ fontSize: 16 }}>{(this.state.user_data && this.state.user_data.name) ? this.state.user_data.name : ""}</span></Link>
									<div>
										<span style={{ marginRight: 5, fontSize: 16 }}>{strings.id}: {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : ""}</span>
										{(this.state.user_data && this.state.user_data.username) ?
											<img style={{ cursor: "pointer" }} onClick={() => this.copyText((this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : "")} src={Copy} alt="Copy" width={12} height={12}></img>
											: null}
									</div>
									<div style={{ display: "flex", alignItems: "center" }}>
										<div style={{ marginRight: 5, fontSize: 12 }}>
											<img src={MemberCenter} alt="MemberCenter" width={12} height={12}></img>
											<span style={{ marginLeft: 5 }}>{"Level"}: {(this.state.user_data && this.state.user_data.level_data && this.state.user_data.level_data.name) ? this.state.user_data.level_data.name : "-"}</span>
										</div>
										<div style={{ marginRight: 5, fontSize: 12 }}>
											<span style={{}}>{"Inviter ID"}: {(this.state.user_data && this.state.user_data.invitation_code) ? this.state.user_data.invitation_code : "-"}</span>
										</div>
										<div style={{ marginRight: 5, fontSize: 12 }}>
											{"Status"}: <span style={{}} className={(this.state.user_data && this.state.user_data.status_data && this.state.user_data.status_data.class) ? this.state.user_data.status_data.class : "-"}>{(this.state.user_data && this.state.user_data.status_data && this.state.user_data.status_data.name) ? this.state.user_data.status_data.name : "-"}</span>
										</div>
									</div>
								</div>
							</div>
							<Link to={"/profile-information"}>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</Link>
						</div>
					</div>

					<div className='flex-center' style={{
						marginTop: 10, flexDirection: "row",
						borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
					}}>
						<Link to="/apibind" className='col-2' style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={ApiBind} alt="ApiBind" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.api_bind}</span>
								</div>
							</div>
						</Link>
						<Link to="/wallet" className='col-2' style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={Wallet} alt="Wallet" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.wallet}</span>
								</div>
							</div>
						</Link>
						<Link to={"/team"} className='col-2' style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={Team} alt="Team" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.team}</span>
								</div>
							</div>
						</Link>
						<Link to={"/notifications"} className='col-2' style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={notifications} alt="Notifications" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.Notifications}</span>
								</div>
							</div>
						</Link>
					</div>
					<div className='flex-center' style={{
						marginTop: 10, flexDirection: "row",
						borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
					}}>
						<Link to="/reward" style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={RewardDetails} alt="RewardDetails" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.rewards}</span>
								</div>
							</div>
						</Link>
						<Link to="/member-center" style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={MemberCenter} alt="MemberCenter" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.affiliate_program}</span>
								</div>
							</div>
						</Link>
						{(this.state.user_data && this.state.user_data.status) ?
							<Link to="/invite-link" style={{
								justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
							}}>
								<div style={{ textAlign: "center" }}>
									<img src={ShareCopy} alt="ShareCopy" width={25} height={25}></img>
									<div style={{ marginLeft: 0 }}>
										<span style={{ fontSize: 10 }}>{strings.share}</span>
									</div>
								</div>
							</Link>
							:
							<div onClick={() => toast.warning(strings.need_to_active_account)} style={{
								justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
							}}>
								<div style={{ textAlign: "center" }}>
									<img src={ShareCopy} alt="ShareCopy" width={25} height={25}></img>
									<div style={{ marginLeft: 0 }}>
										<span style={{ fontSize: 10 }}>{strings.share}</span>
									</div>
								</div>
							</div>
						}
						<Link to={"/security-centre"} style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={SecurityCenter} alt="SecurityCenter" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.security_centre}</span>
								</div>
							</div>
						</Link>

					</div>
					<div className='flex-center' style={{
						marginTop: 10, flexDirection: "row",
						borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
					}}>
						<Link to={{ pathname: "https://tawk.to/chat/62dfbaaa37898912e95fb450/1g8sv2avp" }} target="_blank" style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={HelpLine} alt="HelpLine" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.helpline}</span>
								</div>
							</div>
						</Link>
						<Link to={"/system-setting"} style={{
							justifyContent: "space-between", padding: 2.5, cursor: "pointer", flex: "0.25", alignItems: "center"
						}}>
							<div style={{ textAlign: "center" }}>
								<img src={Settings} alt="Settings" width={25} height={25}></img>
								<div style={{ marginLeft: 0 }}>
									<span style={{ fontSize: 10 }}>{strings.system_setting}</span>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</Layout >
		);
	}
}

export default ProfileScreen;

import React from 'react';
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout';
import strings from "./../../components/Language";

import SliderHome from "./Slider/index";
import Menu from "./Menu";
import BOT1 from "./ListItems/BOT1";
import BOT2 from "./ListItems/BOT2";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import "./home.scss";

import ArrowForword from './../../assets/images/icons/arrow_forword.svg';
import notifications from './../../assets/images/menu/notification.png';

import { getToken, storeUserData, getUserData } from './../../components/Util';

// import { toast } from 'react-toastify';

class HomeScreen extends React.Component {
	constructor(props) {
		super(props);
		var headerData = (this.props && this.props.data) ? this.props.data : {};
		var headerTitle = (this.props && this.props.data && this.props.data.title) ? this.props.data.title : "";
		this.state = {
			headerData: headerData,
			headerTitle: headerTitle,
			user_data: {},
			tabValue: "bot1"
		}
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event, newValue) {
		const self = this;
		self.setState({ tabValue: newValue });
	};

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				var user_data = getUserData();
				this.setState({ access_token: token, user_data: (user_data && user_data.data) ? user_data.data : {} });
			});
		}
	}

	render() {
		return (
			<Layout data={{ "title": strings.benchmark, is_logo: true }}>
				<div className="slider_class">
					<SliderHome />
				</div>
				<div className='border_'></div>
				{/* {(
					this.state.user_data
					&& (this.state.user_data.credit && this.state.user_data.credit.usdt >= 2 && this.state.user_data.credit.usdt <= 20)
					&& (this.state.user_data.status_data && this.state.user_data.status_data.identifier == "active")
				) ?
					<>
						<div className='text-danger' style={{ padding: 20 }}>{strings.fuel_balance_is_insufficient}</div>
						<div className='border_'></div>
					</>
					:
					null
				} */}
				<Link to="/announcement" className='flex-center' style={{ justifyContent: "space-between", padding: 10 }}>
					<div className='flex-center' style={{ flexDirection: "row" }}>
						<img className="" alt="Notifications" src={notifications} width={25} height={25} />
						<div style={{ marginLeft: 10 }}>
							<span style={{ fontSize: 14 }}>{strings.Latest_Announcement}</span>
						</div>
					</div>
					<div>
						<img src={ArrowForword} alt="ArrowForword" width={15} height={15}></img>
					</div>
				</Link>
				<div className='border_'></div>
				<div className="">
					<Menu />
				</div>
				<div className='border_'></div>
				<div className="">
					<Tabs
						value={this.state.tabValue}
						onChange={this.handleChange}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
					>
						<Tab label="DCA" value={"bot1"} />
						<Tab label="ITS" value={"bot2"} />
					</Tabs>
					<div role="tabpanel" hidden={this.state.tabValue !== "bot1"}>
						{this.state.tabValue === "bot1" ?
							<div className="">
								<BOT1 />
							</div>
							: null}
					</div>
					<div role="tabpanel" hidden={this.state.tabValue !== "bot2"}>
						{this.state.tabValue === "bot2" ?
							<div className="">
								<BOT2 />
							</div>
							: null}
					</div>
				</div>
			</Layout>
		);
	}
}

export default HomeScreen;


import axios from 'axios';
import { api } from './../config/api.js';

export async function storeUserData(token, context) {
  await axios({
    method: 'GET',
    url: api.profile_show,
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    }
  }).then(function (ress) {
    if (ress.data && ress.data.error === 200) {
      localStorage.setItem('user_data', JSON.stringify(ress.data.data));
    } else {
      //localStorage.clear();
      //window.location.reload(false);
      // this.props.history.go('/home');
    }
  }).catch(function (e) {
    if(e.response && e.response.status === 401){
      localStorage.clear();
      window.location.reload(false);
    }
    // this.props.history.go('/home');
  });
}

export function getUserData() {
  const userData = localStorage.getItem('user_data');
  if (userData) {
    return { "status": true, data: JSON.parse(userData) };
  } else {
    return { "status": false, data: {} };
  }
};

export async function getToken() {
  const user_token = await localStorage.getItem('user_token');
  if (user_token) {
    return user_token;
  } else {
    localStorage.clear();
    window.location.reload(false);
    // this.props.history.go('/home');
    return false;
  }
};

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

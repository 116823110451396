import React from 'react';
import SubLayout from '../../components/Layout/SubLayout'
import strings from "../../components/Language";

import Moment from 'react-moment';
import _ from 'lodash';

import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../components/config/api.js';
import { getToken, storeUserData } from '../../components/Util';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

class BankScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			user_data: {},
			data: []
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			this.setState({ access_token: token });
			setTimeout(() => {
				this.getData();
			});
		}
	}

	getData() {
		var self = this;
		axios({
			method: 'GET',
			url: api.bank_report,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data.data && ress.data.data.length > 0) {
				self.setState({ data: ress.data.data });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {	
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.Bank_Management }}>
				<div className="text-right" style={{ margin: 20 }}>
					{/* <Button variant="outlined" style={{ paddingBlock: 5, paddingInline: 10, marginRight: 10 }}>WhiteList</Button> */}
					<Button onClick={() => this.props.history.replace({ pathname: `/add-bank` })} variant="outlined" style={{ paddingBlock: 5, paddingInline: 10, }}>{strings.add}</Button>

					<List>
						{
							this.state.data.map((item, i) =>
								<ListItem disablePadding key={i}>
									{/* <Link to={{
										pathname: `/binding/${item.id}`,
										data: item,
										params: item.id
									}} params={{ data: item }} style={{ marginTop: 20, display: "inline-block", width: "100%" }}> */}
									<div style={{ marginTop: 20, display: "inline-block", width: "100%" }}>
										<div className={`flex-center ${item.is_binded ? "api_list_back_active" : "api_list_back"}`} style={{
											justifyContent: "space-between", padding: 20, borderRadius: 5
										}}>
											<div className='' style={{ flexDirection: "row" }}>
												<div style={{ marginLeft: 10 }}>
													<span style={{ fontSize: 18 }}>{item.bank_account_holder}</span>
												</div>
												<div style={{ marginLeft: 10 }}>
													<span style={{ fontSize: 18 }}>{item.bank_account_no.substr(item.bank_account_no.length - 10).replace(/.(?=.{5})/g, '*')}</span>
												</div>
											</div>
											<div className='flex-center' style={{ flexDirection: "row" }}>
												<Moment format="DD MMMM YYYY">{item.created_at}</Moment>
											</div>
										</div>
									</div>
									{/* </Link> */}
								</ListItem>
							)
						}
					</List>
				</div>
			</SubLayout >
		);
	}
}

export default BankScreen;

import React from 'react';
import { Link } from 'react-router-dom'
import strings from "./../../../components/Language";

import './menu.scss';

import ApiBind from './../../../assets/images/icons/api_bind.webp';
// import ApiBind from './../../../assets/images/icons/api_bind_12.png';
import InviteFriend from './../../../assets/images/icons/invite_friend.webp';
// import InviteFriend from './../../../assets/images/icons/invite_friend_12.png';
import Reward from './../../../assets/images/icons/reward.webp';
// import Reward from './../../../assets/images/icons/reward_12.png';
import Wallet from './../../../assets/images/icons/wallet.webp';
// import Wallet from './../../../assets/images/icons/wallet_12.png';

import { toast } from 'react-toastify';
import { getToken, storeUserData, getUserData } from './../../../components/Util';
class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: "",
            user_data: {},
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                var user_data = getUserData();
                if(user_data && user_data.data){
                    this.setState({ user_data: user_data });
                }
            });
        }
    }

    render() {

        return (
            <div className="menu_card">
                <div className="row">
                    <Link to="/apibind" className="width-20 text-center" style={{ width: "25%" }}>
                        <img className="image_icon" style={{ width: 40, height: 40 }} src={ApiBind} alt="ApiBind" />
                        <div className="text_icon">{strings.api_bind}</div>
                    </Link>
                    {(this.state.user_data && this.state.user_data.status) ?
                        <Link to="/invite-link" className="width-20 text-center" style={{ width: "25%" }}>
                            <img className="image_icon" style={{ width: 40, height: 40 }} src={InviteFriend} alt="Invite" />
                            <div className="text_icon">{strings.invite_friend}</div>
                        </Link>
                        :
                        <div onClick={() => toast.warning(strings.need_to_active_account)} className="width-20 text-center" style={{ width: "25%", cursor:"pointer" }}>
                            <img className="image_icon" style={{ width: 40, height: 40 }} src={InviteFriend} alt="Invite" />
                            <div className="text_icon">{strings.invite_friend}</div>
                        </div>
                    }
                    <Link to="/wallet" className="width-20 text-center" style={{ width: "25%" }}>
                        <img className="image_icon" style={{ width: 40, height: 40 }} src={Wallet} alt="Wallet" />
                        <div className="text_icon">{strings.wallet}</div>
                    </Link>
                    <Link to="/reward" className="width-20 text-center" style={{ width: "25%" }}>
                        <img className="image_icon" style={{ width: 40, height: 40 }} src={Reward} alt="Reward" />
                        <div className="text_icon">{strings.rewards}</div>
                    </Link>
                </div>
            </div>
        );
    };
}

export default Menu;

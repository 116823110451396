
import React from 'react';
import Layout from '../../components/Layout';
import strings from '../../components/Language';

import "./notifications.scss"
class NotificationsScreen extends React.Component {
	render() {
		return (
			<Layout data={{ "title": strings.Notifications }}>
				<h5 className='text-center text-danger mt-2'>{strings.no_notifications}</h5>
				{/*<div className="list_item">
					<div className="row">
						<div className='col-8'>
							<div className='width-auto margin-left-1'>
								<span className=''>Transfer In Progress</span>
							</div>
							<div className='width-auto margin-left-1'>
								<span className=''>100 USDT</span>
							</div>
						</div>
						<div className='col-4 avg_label'>
							<div className='width-auto'>
								<label className=''>2021-05-11 00:00:00</label>
							</div>
						</div>
					</div>
					<div className="row list_details gray_color">
						<div className='col-12 text-right'>
							<span className=''>Check Your Wallet For More Details</span>
						</div>
					</div>
				</div>
				<div className="list_item">
					<div className="row">
						<div className='col-8'>
							<div className='width-auto margin-left-1'>
								<span className=''>Withdraw Completed</span>
							</div>
							<div className='width-auto margin-left-1'>
								<span className=''>100 USDT</span>
							</div>
						</div>
						<div className='col-4 avg_label'>
							<div className='width-auto'>
								<label className=''>2021-05-11 00:00:00</label>
							</div>
						</div>
					</div>
					<div className="row list_details gray_color">
						<div className='col-12 text-right'>
							<span className='text_up'>Successful</span>
						</div>
					</div>
				</div>
				<div className="list_item">
					<div className="row">
						<div className='col-8'>
							<div className='width-auto margin-left-1'>
								<span className=''>Welcome New Members</span>
							</div>
							<div className='width-auto margin-left-1'>
								<span className=''>Thank you for register with us.</span>
							</div>
						</div>
						<div className='col-4 avg_label'>
							<div className='width-auto'>
								<label className=''>2021-05-11 00:00:00</label>
							</div>
						</div>
					</div>
					<div className="row list_details gray_color">
						<div className='col-12 text-right'>
							<span className='text_up'></span>
						</div>
					</div>
				</div>*/}
			</Layout>
		);
	}
}
export default NotificationsScreen;

import en from './en';
import zh from './zh';

import LocalizedStrings from 'react-localization';


let strings = new LocalizedStrings({
    en: en,
    zh: zh
});

var value = localStorage.getItem("language");
if (value) {
    strings.setLanguage(value);
} else {
    localStorage.setItem("language", 'en');
    strings.setLanguage('en');
}

export default strings;
import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from "../../components/Language";

import Team01 from './../../assets/images/icons/team01.svg';
import Team02 from './../../assets/images/icons/team02.svg';
import logo from './../../assets/images/icons/logo.svg';

import "./team.scss";

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';

class TeamScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			data: [],
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			this.setState({ access_token: token });
			setTimeout(() => {
				this.getData();
			});
		}
	}
	
	getData() {
		var self = this;

		axios({
			method: 'GET',
			url: api.team,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ data: ress.data.data });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	render() {

		return (
			<SubLayout data={{ "title": strings.team }}>
				<div style={{ marginBottom: 30 }}>
					<div className='flex-center' style={{ justifyContent: "center" }}>
						<img style={{ position: "absolute", left: 0 }} src={Team01} alt="" />
						<img style={{ zIndex: 9 }} src={logo} alt="" />
						<img style={{ position: "absolute", right: 0 }} src={Team02} alt="" />
					</div>

					<div className="list_item" style={{ color: "#000", background: "#FFF", marginBlock: 20, marginInline: 10, borderRadius: 20, marginBottom: 30, }}>
						<div style={{ position: "relative", textAlign: "center", display: "flex", justifyContent: "center" }}>
							<div className='flex-center api_list_back_active' style={{
								justifyContent: "space-between", paddingBlock: 5, paddingInline: 20, borderRadius: 20, display: "inline-block",
								position: "absolute", top: -25
							}}>
								<span style={{ fontSize: 18 }}>{strings.activate_profit}</span>
							</div>
						</div>
						<div style={{ marginBlock: 30, marginInline: 20 }}>
							<div className='row' style={{ background: "#EBEBEB", paddingBlock: 20, paddingInline: 10, borderRadius: 50 }}>
								<div className='col-6 text-center'>
									<div className=''>
										<label className='' style={{ fontSize: 20 }}>{(this.state.data && this.state.data.activate_profit && this.state.data.activate_profit.today) ? this.state.data.activate_profit.today : 0}</label>
									</div>
									<div className=''>
										<label className='' style={{ fontSize: 14 }}>{strings.activate_profit_today}</label>
									</div>
								</div>
								<div className='col-6 text-center' style={{ marginTop: 0, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "#BEBEBE" }}>
									<div className=''>
										<label className='' style={{ fontSize: 20 }}>{(this.state.data && this.state.data.activate_profit && this.state.data.activate_profit.total) ? this.state.data.activate_profit.total : 0}</label>
									</div>
									<div className=''>
										<label className='' style={{ fontSize: 14 }}>{strings.activate_profit_total}</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="list_item" style={{ color: "#000", background: "#FFF", marginBlock: 20, marginInline: 10, borderRadius: 20, marginBottom: 30, }}>
						<div style={{ position: "relative", textAlign: "center", display: "flex", justifyContent: "center" }}>
							<div className='flex-center api_list_back_active' style={{
								justifyContent: "space-between", paddingBlock: 5, paddingInline: 20, borderRadius: 20, display: "inline-block",
								position: "absolute", top: -25
							}}>
								<span style={{ fontSize: 18 }}>{strings.direct_quantification}</span>
							</div>
						</div>
						<div style={{ marginBlock: 30, marginInline: 20 }}>
							<div className='row' style={{ background: "#EBEBEB", paddingBlock: 20, paddingInline: 10, borderRadius: 50 }}>
								<div className='col-6 text-center'>
									<div className=''>
										<label className='' style={{ fontSize: 20 }}>{(this.state.data && this.state.data.direct_quantification && this.state.data.direct_quantification.inactive) ? this.state.data.direct_quantification.inactive : 0}</label>
									</div>
									<div className=''>
										<label className='' style={{ fontSize: 14 }}>{strings.not_activated_yet}</label>
									</div>
								</div>
								<div className='col-6 text-center' style={{ marginTop: 0, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "#BEBEBE" }}>
									<div className=''>
										<label className='' style={{ fontSize: 20 }}>{(this.state.data && this.state.data.direct_quantification && this.state.data.direct_quantification.active) ? this.state.data.direct_quantification.active : 0}</label>
									</div>
									<div className=''>
										<label className='' style={{ fontSize: 14 }}>{strings.activate}</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="list_item" style={{ color: "#000", background: "#FFF", marginBlock: 20, marginInline: 10, borderRadius: 20, marginBottom: 30, }}>
						<div style={{ position: "relative", textAlign: "center", display: "flex", justifyContent: "center" }}>
							<div className='flex-center api_list_back_active' style={{
								justifyContent: "space-between", paddingBlock: 5, paddingInline: 20, borderRadius: 20, display: "inline-block",
								position: "absolute", top: -25
							}}>
								<span style={{ fontSize: 18 }}>{strings.team_quantification}</span>
							</div>
						</div>
						<div style={{ marginBlock: 30, marginInline: 20 }}>
							<div className='row' style={{ background: "#EBEBEB", paddingBlock: 20, paddingInline: 10, borderRadius: 50 }}>
								<div className='col-6 text-center'>
									<div className=''>
										<label className='' style={{ fontSize: 20 }}>{(this.state.data && this.state.data.team_quantification && this.state.data.team_quantification.inactive) ? this.state.data.team_quantification.inactive : 0}</label>
									</div>
									<div className=''>
										<label className='' style={{ fontSize: 14 }}>{strings.not_activated_yet}</label>
									</div>
								</div>
								<div className='col-6 text-center' style={{ marginTop: 0, borderLeftWidth: 1, borderLeftStyle: "solid", borderLeftColor: "#BEBEBE" }}>
									<div className=''>
										<label className='' style={{ fontSize: 20 }}>{(this.state.data && this.state.data.team_quantification && this.state.data.team_quantification.active) ? this.state.data.team_quantification.active : 0}</label>
									</div>
									<div className=''>
										<label className='' style={{ fontSize: 14 }}>{strings.activate}</label>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="list_item" style={{ color: "#000", background: "#FFF", marginBlock: 20, marginInline: 10, borderRadius: 20, marginBottom: 30, }}>
						<div style={{ position: "relative", textAlign: "center", display: "flex", justifyContent: "center" }}>
							<div className='flex-center api_list_back_active' style={{
								justifyContent: "space-between", paddingBlock: 5, paddingInline: 20, borderRadius: 20, display: "inline-block",
								position: "absolute", top: -25
							}}>
								<span style={{ fontSize: 18 }}>{strings.direct_referrals}</span>
							</div>
						</div>
						<div className='row' style={{ marginTop: 30, marginBottom: 20 }}>
							<div className='col-3 text-center'>
								<label className='' style={{}}>{strings.grade}</label>
							</div>
							<div className='col-3 text-center'>
								<label className='' style={{}}>{strings.account}</label>
							</div>
							<div className='col-3 text-center'>
								<label className='' style={{}}>{strings.number_of_team}</label>
							</div>
							<div className='col-3 text-center'>
								<label className='' style={{}}>{strings.registration_time}</label>
							</div>
						</div>
						{(this.state.data && this.state.data.direct_referral && this.state.data.direct_referral.length !== 0) ?
							<div style={{ marginTop: 10 }}>
								{this.state.data.direct_referral.map((item, i) =>
									<div className='row' style={{ marginTop: 5 }}>
										<div className='col-3 text-center'>
											<label className='' style={{}}>{(item.level_data && item.level_data.name) ? item.level_data.name : '-'}</label>
										</div>
										<div className='col-3 text-center'>
											<label className='' style={{}}>{(item.username) ? item.username : '-'}</label>
										</div>
										<div className='col-3 text-center'>
											<label className='' style={{}}>{(item.direct_referral_count) ? item.direct_referral_count : 0}</label>
										</div>
										<div className='col-3 text-center'>
											<label className='' style={{}}>{(item.created_at_) ? item.created_at_ : '-'}</label>
										</div>
									</div>
								)}
							</div>
							:
							<div className='row' style={{ marginTop: 30, marginBottom: 30 }}>
								<div className='col-12 text-center'>
									<label className='' style={{ fontSize: 20 }}>{strings.no_records_found}</label>
								</div>
							</div>
						}
					</div>
				</div>
			</SubLayout >
		);
	}
}

export default TeamScreen;
import React from 'react';
import strings from "./../../../components/Language";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './slider.scss';

import axios from 'axios';
import { api } from './../../../components/config/api.js';
import { getToken, storeUserData } from './../../../components/Util';
import _ from 'lodash';

class SliderHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: "",
            data: [],
            maindata: [],
            is_load: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            });
        }
    }

    getData() {
        var self = this;
        if (self.state.access_token) {
            axios({
                method: 'GET',
                url: api.slider,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    var filesData = [];
                    _.forEach(ress.data.data, function (value) {
                        filesData.push({ url: value.file_name_url });
                    });
                    if (filesData) {
                        self.setState({
                            data: filesData,
                            maindata: ress.data.data,
                            is_load: true
                        });
                    }
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                // toast.error(value[0]);
                            }
                        });
                    } else {
                        // toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                // toast.error(e.message);
            });
        }
    };

    render() {

        const settings = {
            infinite: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: true,
            autoplay: true
        };

        return (
            <div style={{ minHeight: 130 }} >
                {
                    this.state.is_load ?
                        <>
                            <Slider {...settings}>
                                {this.state.maindata.map((item, i) =>
                                    <div key={i + 232}>
                                        <img src={item.file_name_url} style={{
                                            height: (window.innerWidth < 668) ? (window.innerHeight / 4.5) : (window.innerHeight / 3),
                                            maxHeight: (window.innerWidth < 668) ? (window.innerHeight / 4.5) : (window.innerHeight / 3),
                                        }} alt={strings.benchmark}/>
                                    </div>
                                )}
                            </Slider>
                        </>
                        : null
                }
            </div>
        );
    }
}

export default SliderHome;

import React from 'react';
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout';
import strings from "../../components/Language";
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';

import BOT1 from "./ListItems/BOT1";
import BOT2 from "./ListItems/BOT2";

import "./quatitative.scss";

class QuatitativeScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			tabValue: "bot1",
			is_logs: true,
			is_logs_b: false,
			balance: 0,
		}
		this.handleChange = this.handleChange.bind(this);

	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
				this.getData();
			});
		}
	}

	getData() {
		var self = this;
		if (self.state.access_token) {

			axios({
				method: 'GET',
				url: api.balance,
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				console.log("ress.dataL::::::::", ress.data, ress.data.data.balance);
				if (ress.data && ress.data.error === 200) {
					self.setState({ balance: (ress.data && ress.data.data && ress.data.data.balance) ? ress.data.data.balance : 0 });
				}
			}).catch(function (e) {
			});
		}
	};

	handleChange(event, newValue) {
		const self = this;
		if (newValue == "bot1") {
			self.setState({ is_logs: true, is_logs_b: false, })
		}
		else {
			self.setState({ is_logs: false, is_logs_b: true, })
		}
		self.setState({ tabValue: newValue });
	};

	render() {

		return (
			<Layout data={{ "title": strings.Quatitative, is_logs: this.state.is_logs, is_logs_b: this.state.is_logs_b }}>
				<div className="" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<Tabs
						value={this.state.tabValue}
						onChange={this.handleChange}
						variant="scrollable"
						scrollButtons="auto"
						aria-label=""
					>
						<Tab label="DCA" value={"bot1"} />
						<Tab label="ITS" value={"bot2"} />
					</Tabs>

					<div style={{ marginRight: 15 }} className="flex-center">
						<div style={{
							marginRight: 10, borderWidth: 1, borderStyle: "solid", borderRadius: 10, borderColor: "#FFF",
							paddingInline: 15
						}}>
							<div to={"/orders-b"} style={{ fontSize: 14 }}>USDT: {Number.parseFloat(this.state.balance).toFixed(2)}</div>
						</div>
						{this.state.tabValue == 'bot1' ?
							<Link to={"/orders"} style={{ top: 5, fontSize: 16 }}>Logs</Link>
							: null}
						{this.state.tabValue == 'bot2' ?
							<Link to={"/orders-b"} style={{ top: 5, fontSize: 16 }}>Logs</Link>
							: null}
					</div>
				</div>
				<div role="tabpanel" hidden={this.state.tabValue !== "bot1"}>
					{this.state.tabValue === "bot1" ?
						<div className="">
							<BOT1 />
						</div>
						: null}
				</div>
				<div role="tabpanel" hidden={this.state.tabValue !== "bot2"}>
					{this.state.tabValue === "bot2" ?
						<div className="">
							<BOT2 />
						</div>
						: null}
				</div>
			</Layout >
		);
	}
}

export default QuatitativeScreen;
import React from 'react';
import { Link } from 'react-router-dom';
import SubLayout from '../../components/Layout/SubLayout';
import strings from '../../components/Language';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import "./reward.scss";

import NoDataFound from './../../assets/images/icons/no_data_found.svg';
import eye from './../../assets/images/icons/eye.png';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';
import moment from 'moment';

class RewardScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			tabValue: "Trading Profit",
			data: [],
			profit_data: [],
			profit_data_is_load: false,
			activation_reward: [],
			activation_reward_is_load: false,
			profit_reward: [],
			profit_reward_is_load: false,
		}
		this.handleChange = this.handleChange.bind(this);

	}

	handleChange(event, newValue) {
		const self = this;
		self.setState({ tabValue: newValue });
	};

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			this.setState({ access_token: token });
			setTimeout(() => {
				this.getData();
				this.getProfitReport();
				this.getActivationReward();
				this.getProfitReward();
			});
		}
	}

	getData() {
		var self = this;
		axios({
			method: 'GET',
			url: api.reward,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ data: ress.data.data });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	getProfitReport() {
		var self = this;
		axios({
			method: 'GET',
			url: api.profit_report,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({
					profit_data: (ress.data.data && ress.data.data.data) ? ress.data.data.data : [],
					profit_data_is_load: true,
				});
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	getActivationReward() {
		var self = this;
		axios({
			method: 'GET',
			url: api.activation_reward,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.status === 200) {
				self.setState({
					activation_reward: (ress.data.data) ? ress.data.data : [],
					activation_reward_is_load: true,
				});
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	getProfitReward() {
		var self = this;
		axios({
			method: 'GET',
			url: api.profit_reward,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.status === 200) {
				self.setState({
					profit_reward: (ress.data.data) ? ress.data.data : [],
					profit_reward_is_load: true,
				});
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	render() {

		return (
			<SubLayout data={{ "title": strings.reward_details }}>
				<Tabs
					value={this.state.tabValue}
					onChange={this.handleChange}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto tabs example"
				>
					<Tab label={strings.Trading_Profit} value={"Trading Profit"} />
					<Tab label={strings.bot_quantification} value={"Bot Quantification"} />
					<Tab label={strings.profit_quantification} value={"Profit Quantification"} />
				</Tabs>
				<div role="tabpanel" hidden={this.state.tabValue !== "Trading Profit"}>
					{this.state.tabValue === "Trading Profit" && (
						<>
							{this.state.data && this.state.data.trading_profit ?
								<div className="list_item" style={{ background: "#5CBC7C" }}>
									<div className="row">
										<div className='col-6'>
											<div style={{ fontSize: 14, marginBlock: 5 }}>{strings.total_profit_today}</div>
											<div style={{ fontSize: 28, marginBlock: 10 }}>{(this.state.data.trading_profit && this.state.data.trading_profit.profit_today) ? Number.parseFloat(this.state.data.trading_profit.profit_today).toFixed(5) : 0}</div>
										</div>
										<div className='col-6'>
											<div style={{ fontSize: 14, marginBlock: 5 }}>{strings.total_profit_earned}</div>
											<div style={{ fontSize: 28, marginBlock: 10 }}>{(this.state.data.trading_profit && this.state.data.trading_profit.profit_total) ? Number.parseFloat(this.state.data.trading_profit.profit_total).toFixed(5) : 0}</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-6' style={{ textAlign: "left" }}>
											<Link to={"/trading-monthly-report"}>
												{strings.view_monthly_report}
												<img src={eye} alt="Api2" style={{ marginLeft: 2 }} width={17} height={17}></img>
											</Link>
										</div>
										<div className='col-6' style={{ textAlign: "right" }}>
											<Link to={"/trading-details"}>
												{strings.view_details}
												<img src={eye} alt="Api2" style={{ marginLeft: 2 }} width={17} height={17}></img>
											</Link>
										</div>
									</div>
								</div>
								:
								<div className="text-center" style={{ marginTop: 25 }}>
									<img src={NoDataFound} alt="Api2" width={100}></img>
								</div>
							}

							<div style={{ marginTop: 20 }}>
								{this.state.profit_data_is_load && this.state.profit_data && this.state.profit_data.length !== 0 ?
									<>
										{this.state.profit_data.map((item, i) =>
											<>
												<div className="list_item">
													<div className="row">
														<div className='col-4'>
															<div className=''>{(item.created_at) ? moment(item.created_at).format("DD-MM") : ''}</div>
															<div className='gray_color'>{(item.created_at) ? moment(item.created_at).format("YYYY") : ''}</div>
														</div>
														<div className='col-8'>
															<div className=''>
																<span className='gray_color'>{strings.profit}: </span>
																<span className='text_up'>{(item.amount) ? Number.parseFloat(item.amount).toFixed(5) : ''}</span>
															</div>
															<div className='gray_color' style={{ fontSize: 12 }}>{strings.pair}: {(item.pair) ? item.pair : ''}</div>
														</div>
													</div>
												</div>
											</>
										)}
									</>
									:
									<></>
								}
							</div>
						</>
					)}
				</div>
				<div role="tabpanel" hidden={this.state.tabValue !== "Bot Quantification"}>
					{this.state.tabValue === "Bot Quantification" && (
						<>
							{this.state.data && this.state.data.bot_quantification ?
								<div className="list_item" style={{ background: "#5CBC7C" }}>
									<div className="row">
										<div className='col-6'>
											<div style={{ fontSize: 14, marginBlock: 5 }}>{strings.total_profit_today}</div>
											<div style={{ fontSize: 28, marginBlock: 10 }}>{(this.state.data.bot_quantification && this.state.data.bot_quantification.profit_today) ? Number.parseFloat(this.state.data.bot_quantification.profit_today).toFixed(5) : 0}</div>
										</div>
										<div className='col-6'>
											<div style={{ fontSize: 14, marginBlock: 5 }}>{strings.total_profit_earned}</div>
											<div style={{ fontSize: 28, marginBlock: 10 }}>{(this.state.data.bot_quantification && this.state.data.bot_quantification.profit_total) ? Number.parseFloat(this.state.data.bot_quantification.profit_total).toFixed(5) : 0}</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-6' style={{ textAlign: "left" }}>
											<Link to={"/bot-monthly-report"}>
												{strings.view_monthly_report}
												<img src={eye} alt="Api2" style={{ marginLeft: 2 }} width={17} height={17}></img>
											</Link>
										</div>
										<div className='col-6' style={{ textAlign: "right" }}>
											<Link to={"/bot-reward-details"}>
												{strings.view_details}
												<img src={eye} alt="Api2" style={{ marginLeft: 2 }} width={17} height={17}></img>
											</Link>
										</div>
									</div>
								</div>
								:
								<div className="text-center" style={{ marginTop: 25 }}>
									<img src={NoDataFound} alt="Api2" width={100}></img>
								</div>
							}

							<div style={{ marginTop: 20 }}>
								{this.state.activation_reward_is_load && this.state.activation_reward && this.state.activation_reward.length !== 0 ?
									<>
										{this.state.activation_reward.map((item, i) =>
											<>
												<div className="list_item">
													<div className="row">
														<div className='col-4'>
															<div className=''>{(item.created_at) ? moment(item.created_at).format("DD-MM") : ''}</div>
															<div className='gray_color'>{(item.created_at) ? moment(item.created_at).format("YYYY") : ''}</div>
														</div>
														<div className='col-8'>
															<div className=''>
																<span className='gray_color'>{strings.profit}: </span>
																<span className='text_up'>{(item.amount) ? Number.parseFloat(item.amount).toFixed(5) : ''}</span>
															</div>
															<div className='gray_color' style={{ fontSize: 12 }}>{strings.description}: {(item.description) ? item.description : ''}</div>
														</div>
													</div>
												</div>
											</>
										)}
									</>
									:
									<></>
								}
							</div>
						</>
					)}
				</div>
				<div role="tabpanel" hidden={this.state.tabValue !== "Profit Quantification"}>
					{this.state.tabValue === "Profit Quantification" && (
						<>
							{this.state.data && this.state.data.profit_quantification ?
								<div className="list_item" style={{ background: "#5CBC7C" }}>
									<div className="row">
										<div className='col-6'>
											<div style={{ fontSize: 14, marginBlock: 5 }}>{strings.total_profit_today}</div>
											<div style={{ fontSize: 28, marginBlock: 10 }}>{(this.state.data.profit_quantification && this.state.data.profit_quantification.profit_today) ? Number.parseFloat(this.state.data.profit_quantification.profit_today).toFixed(5) : 0}</div>
										</div>
										<div className='col-6'>
											<div style={{ fontSize: 14, marginBlock: 5 }}>{strings.total_profit_earned}</div>
											<div style={{ fontSize: 28, marginBlock: 10 }}>{(this.state.data.profit_quantification && this.state.data.profit_quantification.profit_total) ? Number.parseFloat(this.state.data.profit_quantification.profit_total).toFixed(5) : 0}</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-6' style={{ textAlign: "left" }}>
											<Link to={"/profit-monthly-report"}>
												{strings.view_monthly_report}
												<img src={eye} alt="Api2" style={{ marginLeft: 2 }} width={17} height={17}></img>
											</Link>
										</div>
										<div className='col-6' style={{ textAlign: "right" }}>
											<Link to={"/profit-details"}>
												{strings.view_details}
												<img src={eye} alt="Api2" style={{ marginLeft: 2 }} width={17} height={17}></img>
											</Link>
										</div>
									</div>
								</div>
								:
								<div className="text-center" style={{ marginTop: 25 }}>
									<img src={NoDataFound} alt="Api2" width={100}></img>
								</div>
							}

							<div style={{ marginTop: 20 }}>
								{this.state.profit_reward_is_load && this.state.profit_reward && this.state.profit_reward.length !== 0 ?
									<>
										{this.state.profit_reward.map((item, i) =>
											<>
												<div className="list_item">
													<div className="row">
														<div className='col-4'>
															<div className=''>{(item.created_at) ? moment(item.created_at).format("DD-MM") : ''}</div>
															<div className='gray_color'>{(item.created_at) ? moment(item.created_at).format("YYYY") : ''}</div>
														</div>
														<div className='col-8'>
															<div className=''>
																<span className='gray_color'>{strings.profit}: </span>
																<span className='text_up'>{(item.amount) ? Number.parseFloat(item.amount).toFixed(5) : ''}</span>
															</div>
															<div className='gray_color' style={{ fontSize: 12 }}>{strings.description}: {(item.description) ? item.description : ''}</div>
														</div>
													</div>
												</div>
											</>
										)}
									</>
									:
									<></>
								}
							</div>
						</>
					)}
				</div>
			</SubLayout>
		);
	}
}

export default RewardScreen;
import React from 'react';
import { Link } from 'react-router-dom'
import strings from "../../../components/Language";

import './listitems.scss';

import Bitcoin from './../../../assets/images/icons/bitcoin.svg';

import axios from 'axios';
import { api } from '../../../components/config/api.js';
import { getToken, storeUserData } from '../../../components/Util';
import _ from 'lodash';

import { io } from "socket.io-client";
class BOT2Items extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            access_token: "",
            botLiveData: [],
            data: [],
            pariNames: "",
            get_live_bot: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            });

            try {
                var self = this;
                var socket = io.connect('https://price.benchmarkbot.io');
                socket.on('connect', function (data) {
                });
                socket.on('price', function (data) {
                    self.setState({
                        botLiveData: data,
                        get_live_bot: true,
                    })
                    // self.getData();
                });
            }
            catch (e) {
            }
        }
    }

    getData() {
        var self = this;
        if (self.state.access_token) {
            axios({
                method: 'GET',
                url: api.botb_report_active,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {

                    var parinames = "";
                    var botData = _.filter(ress.data.data, function (o) {
                        o.average_ = o.total_usdt_qty / o.total_coin_qty;
                        o.is_calculate = (o.total_coin_qty > 0) ? true : false;

                        if (o.pair.name) {
                            if (parinames) {
                                parinames += `,`;
                            }
                            parinames += `"` + o.pair.name + `"`;
                        }

                        return o;
                    });
                    self.setState({ data: botData, pariNames: parinames });

                    if (parinames && parinames.length != 0) {
                        self.getLiveData();
                        setInterval(() => {
                            self.getLiveData();
                        }, 10000);
                    }

                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                // toast.error(value[0]);
                            }
                        });
                    } else {
                        // toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                // toast.error(e.message);
            });
        }
    };

    getLiveData() {
        var self = this;
        if (self.state.access_token) {

            // var API = encodeURI("https://api.binance.com/api/v3/ticker/24hr?symbols=" + self.state.pariNames);
            var API = "https://api.binance.com/api/v3/ticker/24hr?symbols=[" + self.state.pariNames + "]";
            axios({
                method: 'GET',
                // url: api.pair_report,
                url: API,
            }).then(function (ress) {
                if (ress && ress.status == 200) {
                    var data = _.filter(self.state.data, function (o) {
                        var finalLiveData = _.find(ress.data, { symbol: o.pair.name });
                        if (finalLiveData) {
                            o.liveData = finalLiveData;
                        }
                        return o;
                    })
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                // toast.error(value[0]);
                            }
                        });
                    } else {
                        // toast.error(ress.data.message);
                    }
                }
                self.setState({ is_load: false });
            }).catch(function (e) {
                // toast.error(e.message);
            });
        }
    };

    render() {
        return (
            <>
                {
                    this.state.data.length === 0 ? <h5 className='text-danger text-center mt-2'>{strings.no_record_found}</h5> : null
                }
                {
                    this.state.data.map((item, i) =>
                        <div key={i + 901} className="list_item">
                            <Link to={{ pathname: `/trad-bot-2/${item._id}` }}>
                                <div className="row">
                                    <div className='col-6'>
                                        <div className='flex-center'>
                                            <img className="image_icon" src={(item.pair && item.pair.icon_url) ? item.pair.icon_url : Bitcoin} alt="Bitcoin" width={25} height={25} />
                                            <div className='width-auto margin-left-1'>
                                                <span className=''>{(item.pair && item.pair.name) ? item.pair.name : ''}</span>
                                            </div>
                                            <div className='cycle margin-left-1'>
                                                <label className=''>{(item.type) ? strings.cycle : strings.one_shot}</label>
                                            </div>
                                        </div>
                                        <div className='list_details gray_color' style={{ fontSize: 12 }}>
                                            <div className='width-auto margin-left-1'>
                                                <label>{strings.quantity}: <span className=''>{(item.total_orders_qty) ? Number.parseFloat(item.total_orders_qty).toFixed(4) : '0'}</span></label>
                                            </div>
                                            <div className='width-auto margin-left-1'>
                                                <label>{strings.latest_price}: <span className=''>
                                                    {(this.state.get_live_bot) ? Number.parseFloat(this.state.botLiveData[item.pair.name]).toFixed(4) : ((item.liveData && item.liveData.lastPrice) ? Number.parseFloat(item.liveData.lastPrice).toFixed(4) : "0.00")}
                                                </span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 text-right' style={{ fontSize: 12, alignSelf: "center" }}>
                                        <div className='width-auto margin-left-1'>
                                            <label>{strings.total_order_number}: <span className=''>
                                                {(item && item.total_orders) ? item.total_orders : 0}
                                            </span></label>
                                        </div>
                                        <div className='width-auto margin-left-1'>
                                            <label>{strings.margin_call_limit}: <span className=''>
                                                {(item && item.margin_call_limit) ? item.margin_call_limit : 7}
                                            </span></label>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                }
            </>
        );
    }
}

export default BOT2Items;

import React from 'react';
import { Link } from 'react-router-dom'
import SubLayout from '../../components/Layout/SubLayout'
import strings from "./../../components/Language";
import "./apibind.scss"

import ArrowForword from './../../assets/images/icons/arrow_forword.svg';

import Api1 from './../../assets/images/icons/api1.svg';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';

class APIBindScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			data: [],
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
				this.getData();
			});
		}
	}

	comingSoon() {
		toast.warning(strings.Coming_Soon);
	}

	getData() {
		var self = this;
		if (self.state.access_token) {

			axios({
				method: 'GET',
				url: api.bind_trading_platform,
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					self.setState({ data: ress.data.data });
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.api_bind }}>
				<div style={{ margin: 10 }}>
					<List>
						{
							this.state.data.map((item, i) =>
								<ListItem disablePadding key={i}>
									{item.name === "Huobi" || item.name === "Coinbase" ?
										<div onClick={() => this.comingSoon()} style={{ marginBottom: 20, display: "inline-block", width: "100%" }}>
											<div className={`flex-center ${item.is_binded ? "api_list_back_active" : "api_list_back"}`} style={{
												justifyContent: "space-between", padding: 10, borderRadius: 5
											}}>
												<div className='flex-center' style={{ flexDirection: "row" }}>
													<img src={item.icon ? item.icon : Api1} alt="Api1" width={30} height={30}></img>
													<div style={{ marginLeft: 10 }}>
														<span style={{ fontSize: 14 }}>{item.name}</span>
													</div>
												</div>
												<div className='flex-center' style={{ flexDirection: "row" }}>
													<div className='back_inputColor' style={{ marginRight: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 20, paddingTop: 5, paddingBottom: 5 }}>
														<span style={{ fontSize: 14 }}>{item.is_binded ? strings.bound : strings.unbound}</span>
													</div>
													<img src={ArrowForword} alt="ArrowForword"></img>
												</div>
											</div>
										</div>
										:
										<Link to={{ pathname: `/binding/${item.id}` }} style={{ marginBottom: 20, display: "inline-block", width: "100%" }}>
											<div className={`flex-center ${item.is_binded ? "api_list_back_active" : "api_list_back"}`} style={{
												justifyContent: "space-between", padding: 10, borderRadius: 5
											}}>
												<div className='flex-center' style={{ flexDirection: "row" }}>
													<img src={item.icon ? item.icon : Api1} alt="Api1" width={30} height={30}></img>
													<div style={{ marginLeft: 10 }}>
														<span style={{ fontSize: 14 }}>{item.name}</span>
													</div>
												</div>
												<div className='flex-center' style={{ flexDirection: "row" }}>
													<div className='back_inputColor' style={{ marginRight: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 20, paddingTop: 5, paddingBottom: 5 }}>
														<span style={{ fontSize: 14 }}>{item.is_binded ? strings.bound : strings.unbound}</span>
													</div>
													<img src={ArrowForword} alt="ArrowForword"></img>
												</div>
											</div>
										</Link>
									}
								</ListItem>
							)
						}
					</List>
				</div>
			</SubLayout >
		);
	}
}

export default APIBindScreen;

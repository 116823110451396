import React from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../Layout'
import strings from "../Language";

const NotFound = () => {
  const location = useLocation()

  return (
    <Layout>
      <div className="form-cover error-middle">
        <h1>404</h1>
        <p>
          {strings.we_could_not_find} <u>{location.pathname}</u> {strings.page}
        </p>
      </div>
    </Layout>
  )
}

export default NotFound

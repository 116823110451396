import React from 'react'
import { withRouter } from 'react-router-dom'
import Button from '@mui/material/Button';
import SubLayout from '../../components/Layout/SubLayout'
import strings from "../../components/Language";
//  CSS
import './../../assets/styles/style.scss';

import _ from 'lodash';
import { Form, Input } from 'antd';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';

class AddBankScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            access_token: "",
            bank_name: "",
            bank_account_holder: "",
            bank_account_no: "",
            bank_branch: "",
            bank_city: "",
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
            });
        }
    }

    componentWillUnmount() {
    }

    onFinish(values) {
        var self = this;
        axios({
            method: 'POST',
            url: api.bank_store,
            data: values,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                toast.success(ress.data.message);
                self.props.history.replace({ pathname: `/bank` })
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    render() {
        return (
            <SubLayout data={{ "title": strings.Add_Bank }}>
                <Form
                    onFinish={(data) => this.onFinish(data)}
                >
                    <div className="list_item" style={{ padding: 20, paddingBlock: 10, marginTop: 20 }}>
                        <div>
                            <label className="">{strings.name}</label>
                            <div className="form-custom form-label form-icon mb-3">
                                <Form.Item
                                    name="bank_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: strings.Add_Bank_input,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={e => this.setState({ bank_name: e.target.value })}
                                        className="form-control rounded-xs"
                                        placeholder={strings.Add_Bank_enter}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <label className="">{strings.Account_Holder}</label>
                            <div className="form-custom form-label form-icon mb-3">
                                <Form.Item
                                    name="bank_account_holder"
                                    rules={[
                                        {
                                            required: true,
                                            message: strings.Account_Holder_input,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={e => this.setState({ bank_account_holder: e.target.value })}
                                        className="form-control rounded-xs"
                                        placeholder={strings.Account_Holder_enter}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <label className="">{strings.Account_Number}</label>
                            <div className="form-custom form-label form-icon mb-3">
                                <Form.Item
                                    name="bank_account_no"
                                    rules={[
                                        {
                                            required: true,
                                            message: strings.Account_Number_input,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={e => this.setState({ bank_account_no: e.target.value })}
                                        className="form-control rounded-xs"
                                        placeholder={strings.Account_Number_enter}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <label className="">{strings.Branch}</label>
                            <div className="form-custom form-label form-icon mb-3">
                                <Form.Item
                                    name="bank_branch"
                                    rules={[
                                        {
                                            required: true,
                                            message: strings.Branch_input,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={e => this.setState({ bank_branch: e.target.value })}
                                        className="form-control rounded-xs"
                                        placeholder={strings.Branch_enter}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <label className="">{strings.City}</label>
                            <div className="form-custom form-label form-icon mb-3">
                                <Form.Item
                                    name="bank_city"
                                    rules={[
                                        {
                                            required: true,
                                            message: strings.City_input,
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={e => this.setState({ bank_city: e.target.value })}
                                        className="form-control rounded-xs"
                                        placeholder={strings.City_enter}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div style={{ padding: 20, paddingBlock: 10, marginTop: 20 }}>
                        <Form.Item>
                            <Button type="primary" className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full '
                                style={{ fontSize: 12, paddingBlock: 15, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10 }}>
                                {strings.confirm}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </SubLayout>


        );
    }
}

export default withRouter(AddBankScreen);
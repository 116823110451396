import React from 'react';
// import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import SubLayout from '../../components/Layout/SubLayout'
import strings from '../../components/Language';

import NoDataFound from './../../assets/images/icons/no_data_found.svg';

class MyPolicySettingScreen extends React.Component {
	render() {
		return (
			<SubLayout data={{ "title": strings.my_policy_setting }}>

				<div style={{ marginTop: 25 }}>
					<div className="text-right" style={{ marginRight: 10 }}>
						<Button variant="outlined" style={{ padding: 12 }}>{strings.add}</Button>
					</div>

					<div className="text-center" style={{ marginTop: 25 }}>
						<img src={NoDataFound} alt="Api2"></img>
					</div>
				</div>

			</SubLayout >
		);
	}
}

export default MyPolicySettingScreen;

import React from 'react';
import { withRouter } from 'react-router-dom'
import SubLayout from '../../components/Layout/SubLayout'
import strings from "../../components/Language";
import { getToken, storeUserData } from './../../components/Util';
import "./profile.scss"
import _ from 'lodash';

import { Form, Input, Button } from 'antd';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
class TransactionPasswordScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			loading_code: false,
			access_token: "",
			otp_code: "",
		}
		this.handleChange = this.handleChange.bind(this);

	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			this.setState({ access_token: token });
		}
	}

	handleChange(event, newValue) {
		const self = this;
		self.setState({ tabValue: newValue });
	};

	onFinish(values) {
		var self = this;

		self.setState({ loading: true });
		axios({
			method: 'POST',
			url: api.transaction_password_update,
			data: values,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.props.history.replace({ pathname: `/security-centre` })
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ loading: false });
		}).catch(function (e) {
			//toast.error(e.message);
			self.setState({ loading: false });
		});
	};

	sendCode() {
		var self = this;
		self.setState({ loading_code: true });
		axios({
			method: 'POST',
			url: api.transaction_password_send_code,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ otp_code: ress.data.data.otp_code })
				toast.success(ress.data.message);
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ loading_code: false });
		}).catch(function (e) {
			//toast.error(e.message);
			self.setState({ loading_code: false });
		});
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.transaction_password }}>
				<div className="profile card-style">
					<div style={{ marginTop: 20 }}>

						<Form
							name="sign-in"
							onFinish={(data) => this.onFinish(data)}
						>
							<div style={{ marginBottom: 10 }}>
								<label className="">{strings.new_password}</label>
								<div>
									<Form.Item
										name="password"
										rules={[
											{ required: true, message: strings.Please_input_your_new_password },
										]}
									>
										<Input.Password
											className="form-control rounded-xs password-class"
											placeholder={strings.please_enter_new_password}
										/>
									</Form.Item>
								</div>
							</div>
							<div style={{ marginBottom: 10 }}>
								<label className="">{strings.retype_password}</label>
								<div>
									<Form.Item
										name="password_confirmation"
										rules={[
											{
												required: true,
												message: strings.retype_password_input
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (!value || getFieldValue('password') == value) {
														return Promise.resolve();
													}
													return Promise.reject(strings.retype_password_not_match);
												},
											}),
										]}
									>
										<Input.Password
											className="form-control rounded-xs password-class"
											placeholder={strings.please_enter_retype_password}
										/>
									</Form.Item>
								</div>
							</div>
							<div>
								<label className="">{strings.verification_code}</label>
								<div className='row'>
									<div className='col-8 col-md-11 col-px-2'>
										<div className="form-custom form-label form-icon mb-3">
											<Form.Item
												name="verification_code"
												rules={[
													{
														required: true,
														message: strings.input_your_verification_code,
													},
													// ({ getFieldValue }) => ({
													// 	validator(rule, value) {
													// 		if (!value || self.state.otp_code == value) {
													// 			return Promise.resolve();
													// 		}
													// 		return Promise.reject(strings.otp_not_match);
													// 	},
													// }),
												]}
											>
												<Input
													className="form-control rounded-xs"
													placeholder={strings.verification_code_enter}
												/>
											</Form.Item>
										</div>
									</div>
									<div className='col-4 col-md-1 col-px-2'>
										<Button
											loading={this.state.loading_code}
											variant="outlined"
											onClick={(data) => this.sendCode()}
											style={{
												opacity: this.state.loading_code ? 0.7 : 1, padding: 10, borderWidth: 1, borderColor: "#446CE3", borderRadius: 5,
												background: "transparent", color: "#FFF",
											}}>
											{this.state.loading_code ? strings.loading : strings.send_code}
										</Button>
									</div>
								</div>
							</div>

							<Form.Item>
								<Button
									loading={this.state.loading}
									type="primary"
									htmlType="submit"
									className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full'
									style={{ opacity: this.state.loading ? 0.7 : 1, fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10 }}>
									{this.state.loading ? strings.loading : strings.done}
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</SubLayout >
		);
	}
}

export default withRouter(TransactionPasswordScreen);

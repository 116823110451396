
import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from "../../components/Language";

import { getToken, storeUserData } from '../../components/Util';

import TradingViewWidget, { Themes } from 'react-tradingview-widget';

class ChartDetailsScreen extends React.Component {

	constructor(props) {
		super(props);
		var pair_name = this.props.match.params.pair_name;
		this.state = {
			access_token: "",
			pair_name: pair_name,
			botLiveData: [],
			data: [],
			get_live_bot: false,
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
			});
		}
	}

	render() {
		return (
			<SubLayout data={{ "title": this.state.pair_name + " " + strings.Chart }}>
				<>
					<TradingViewWidget
						symbol={`BINANCE:${this.state.pair_name}`}
						autosize={true}
						theme={Themes.DARK}
						interval={60}
						timezone={"Asia/Hong_Kong"}
						style={'1'}
						locale={'in'}
						toolbar_bg={'#f1f3f6'}
						enable_publishing={false}
						allow_symbol_change={false}
						container_id={"tradingview_2dd38"}
					/>
				</>
			</SubLayout>
		);
	}
}
export default ChartDetailsScreen;


import React from 'react';
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout';
import strings from "../../components/Language";
import axios from 'axios';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';
class ChartScreen extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			botLiveData: [],
			data: [],
			is_load: true,
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
				this.getData();
			});
		}
	}

	getData() {
		var self = this;
		if (self.state.access_token) {

			axios({
				method: 'GET',
				// url: api.pair_report,
				// url: "https://api.coincap.io/v2/assets?ids=bitcoin,ethereum,binance-coin,ripple,ripple,polkadot,ftx-token,xrp,cardano,solana",
				url: "https://api.coincap.io/v2/assets?ids=bitcoin,ethereum,binance-coin,ripple,ripple,polkadot,xrp,cardano,solana",
			}).then(function (ress) {
				if (ress.data && ress.data.data) {
					self.setState({ data: ress.data.data, is_load: false });
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								// toast.error(value[0]);
							}
						});
					} else {
						// toast.error(ress.data.message);
					}
				}
				self.setState({ is_load: false });
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	render() {
		return (
			<Layout data={{ "title": strings.Chart }}>
				<>
					{!this.state.is_load ?
						<>
							{this.state.data.map((item, i) =>
								<div key={i + 1001} className="list_item">
									<Link to={{ pathname: `/ChartDetails/${item.symbol + "USDT"}` }}>
										{/* <Link to={{ pathname: `/ChartDetails` }}> */}
										<div className="row">
											<div className='col-8 flex-center'>
												<div className='width-auto margin-left-1'>
													<span className=''>{(item.symbol) ? item.symbol : ''}</span>
													<span className='gray_color' style={{ fontSize: 12, marginLeft: 5 }}>{"/USDT"}</span>
												</div>
											</div>
											<div className='col-4 avg_label'>
												<div className='width-fit-content avg_price'>
													<label className={(item.changePercent24Hr > 0) ? "text_up" : "text_down"}>
														{item.changePercent24Hr ? Number.parseFloat(item.changePercent24Hr).toFixed(2) : "0"}%
													</label>
												</div>
											</div>
										</div>
										<div className="row list_details gray_color" style={{ fontSize: 12 }}>
											<div className='col-6'>
												<div className='width-auto margin-left-1'>
													<label>{strings.latest_price}: <span className=''>
														{(item.priceUsd) ? Number.parseFloat(item.priceUsd).toFixed(5) : '0.00'}
													</span></label>
												</div>
											</div>
										</div>
									</Link>
								</div>
							)
							}
						</>
						: null
					}
				</>
			</Layout>
		);
	}
}
export default ChartScreen;

import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from '../../components/Language';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

import "./reward.scss";

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../components/config/api.js';
import { getToken, storeUserData } from '../../components/Util';
import _ from 'lodash';
import moment from 'moment';

class ProfitMonthlyReportScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			tabValue: "Trading Profit",
			data: [],
			profit_data: [],
			profit_data_is_load: false,
			activation_reward: [],
			activation_reward_is_load: false,
			profit_reward: [],
			profit_reward_is_load: false,
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			this.setState({ access_token: token });
			setTimeout(() => {
				this.getData();
			});
		}
	}

	getData() {
		var self = this;
		axios({
			method: 'GET',
			url: api.reward,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ data: ress.data.data });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	render() {

		return (
			<SubLayout data={{ "title": strings.reward_details }}>
				<div style={{ marginTop: 20 }}>
					<>
						<div className="list_item">
							<div className="row">
								<div className='col-4'>
									<div className=''>{moment().format("DD-MM")}</div>
									<div className='gray_color'>{moment().format("YYYY")}</div>
								</div>
								<div className='col-8'>
									<div className=''>
										<span className='gray_color'>{"strings.profit"}: </span>
										<span className='text_up'>{Number.parseFloat(1001).toFixed(5)}</span>
									</div>
									<div className='gray_color' style={{ fontSize: 12 }}>{strings.pair}: {'item.pair'}</div>
								</div>
							</div>
						</div>
					</>
				</div>
			</SubLayout>
		);
	}
}

export default ProfitMonthlyReportScreen;
import React from 'react';
import { withRouter } from 'react-router-dom'
import SubLayout from '../../components/Layout/SubLayout'
import strings from '../../components/Language';
import { getToken, storeUserData } from './../../components/Util';
import "./profile.scss"

import { Form, Input, Button } from 'antd';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import _ from 'lodash';
class ChangePasswordScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			access_token: "",
			loading: false,
		}
		this.handleChange = this.handleChange.bind(this);

	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
			});
		}
	}

	handleChange(event, newValue) {
		const self = this;
		self.setState({ tabValue: newValue });
	};

	onFinish(values) {
		var self = this;

		self.setState({ loading: true });
		axios({
			method: 'POST',
			url: api.password_update,
			data: values,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.props.history.replace({ pathname: `/security-centre` })
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ loading: false });
		}).catch(function (e) {
			//toast.error(e.message);
			self.setState({ loading: false });
		});
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.change_password }}>
				<div className="profile card-style">
					<div style={{ marginTop: 20 }}>

						<Form
							name="sign-in"
							onFinish={(data) => this.onFinish(data)}
						>
							<div style={{ marginBottom: 10 }}>
								<label className="">Old Password</label>
								<div>
									<Form.Item
										name="current_password"
										rules={[
											{ required: true, message: strings.input_your_old_password },
										]}
									>
										<Input.Password
											className="form-control rounded-xs password-class"
											placeholder={strings.enter_old_password}
										/>
									</Form.Item>
								</div>
							</div>
							<div style={{ marginBottom: 10 }}>
								<label className="">Password</label>
								<div>
									<Form.Item
										name="password"
										rules={[
											{ required: true, message: strings.password_input },
										]}
									>
										<Input.Password
											className="form-control rounded-xs password-class"
											placeholder={strings.please_enter_password}
										/>
									</Form.Item>
								</div>
							</div>
							<div style={{ marginBottom: 10 }}>
								<label className="">Retype Password</label>
								<div>
									<Form.Item
										name="password_confirmation"
										rules={[
											{
												required: true,
												message: strings.retype_password_input
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (!value || getFieldValue('password') == value) {
														return Promise.resolve();
													}
													return Promise.reject(strings.retype_password_not_match);
												},
											}),
										]}
									>
										<Input.Password
											className="form-control rounded-xs password-class"
											placeholder={strings.please_enter_retype_password}
										/>
									</Form.Item>
								</div>
							</div>

							<Form.Item>
								<Button
									loading={this.state.loading}
									htmlType="submit"
									type="primary"
									className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full '
									style={{ opacity: this.state.loading ? 0.7 : 1, fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10 }}
								>
									{this.state.loading ? strings.loading : strings.confirm}
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</SubLayout >
		);
	}
}

export default withRouter(ChangePasswordScreen);


import React from 'react';
import Layout from '../../components/Layout';
import strings from "./../../components/Language";

import communityBack from './../../assets/images/other/community_back.png';
import community1 from './../../assets/images/other/community_1.png';
import walletWhite from './../../assets/images/icons/wallet_white.png';

import { toast } from 'react-toastify';
class CommunityScreen extends React.Component {

	comingSoon() {
		toast.warning(strings.Coming_Soon);
	}

	render() {
		return (
			<Layout data={{ "title": strings.Community }}>
				<div className='offset-md-4 col-md-4 trad_bot' style={{ backgroundImage: `url(${communityBack})`, height: "100%", backgroundSize: "cover" }}>
					<div style={{ paddingBlock: 30, marginInline: 10 }}>
						<img src={community1} alt="Logo" width={"100%"} />
					</div>
					<div style={{ marginBlock: 30, marginInline: 10, textAlign: "center" }}>
						<h2 style={{ lineHeight: 1.5 }}>{strings.CONNECT_TO_ACCESS_HOLDER_AREA}</h2>
					</div>
					<div style={{ marginBlock: 30, marginInline: 10, textAlign: "center" }}>
						<div
							onClick={() => this.comingSoon()}
							style={{
								background: "#432a76", fontSize: 20,
								width: "fit-content", margin: "0 auto",
								paddingInline: 15, paddingBlock: 5, borderRadius: 15, lineHeight: 1.5, cursor: "pointer",
								display: "flex", alignItems: "center"
							}}>
							{strings.CONNECT_WALLET}
							<img src={walletWhite} alt="Logo" width={25} style={{ marginLeft: 5 }} />
						</div>
					</div>
					<div style={{ borderTopWidth: 0.5, borderTopStyle: "solid", borderColor: "#FFF", paddingTop: 10, margin: 15 }}>
						<div style={{ fontSize: 16 }}>
							{strings.terms_conditions}
						</div>
						<div style={{ fontSize: 12, color: "#999" }}>
							<div>{strings.terms_conditions_1}</div>
							<div>{strings.terms_conditions_2}</div>
							<div>{strings.terms_conditions_3}</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}
export default CommunityScreen;


import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from "./../../components/Language";

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';
import _ from 'lodash';

class AnnouncementDetailScreen extends React.Component {

	constructor(props) {
		super(props);
		var params_id = this.props.match.params.id;
		this.state = {
			access_token: "",
			params_id: params_id,
			data: {},
		}
	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({ access_token: token });
				this.getData();
			});
		}
	}

	getData() {
		var self = this;
		if (self.state.access_token && self.state.params_id) {
			axios({
				method: 'GET',
				url: api.announcement + "/" + self.state.params_id,
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					self.setState({ data: ress.data.data });
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.Announcement_Detail }}>
				<div className="list_item">
					<div>{(this.state.data && this.state.data.subject) ? this.state.data.subject : ''}</div>
					<br />
					<div>{(this.state.data && this.state.data.created_at_) ? this.state.data.created_at_ : ''}</div>
					<br />
					<br />
					<div dangerouslySetInnerHTML={{ __html: (this.state.data && this.state.data.message) ? this.state.data.message.split('\n').join('<br />') : ''}}></div>
					<br />
				</div>
			</SubLayout>
		);
	}
}
export default AnnouncementDetailScreen;

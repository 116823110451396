import React from 'react';
import SubLayout from '../../components/Layout/SubLayout'
import strings from '../../components/Language';
import "./profile.scss"
import _ from 'lodash';

import Avatar1 from './../../assets/images/icons/avatar1.svg';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData } from './../../components/Util';


import moment from 'moment';
import Countdown from 'react-countdown';
class ProfileInfoScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            access_token: "",
            user_data: {},
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                this.getData();
            });
        }
    }

    getData() {
        var self = this;

        axios({
            method: 'GET',
            url: api.profile_show,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {

                var data = ress.data.data;
                // data.expired_at_data.expired_at = moment().add(7, 'days');
                if (data.expired_at_data && data.expired_at_data.expired_at) {
                    var date1 = moment();
                    var expired_at = moment(data.expired_at_data.expired_at);
                    data.expired_at_data.expired_days = expired_at.diff(date1, 'days')
                }

                self.setState({ user_data: data });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    handleFileSelect = (event) => {
        const self = this;

        var selectedFile = event.target.files[0]

        event.preventDefault()
        const formData = new FormData();
        formData.append("avatar", selectedFile);

        axios({
            method: 'POST',
            url: api.profile_update_avatar,
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                // 'Content-Type': 'application/json',
                "Content-Type": "multipart/form-data"
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {

                toast.success(ress.data.message);
                self.getData();
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {

                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    }

    activeProfile() {
        const self = this;
        axios({
            method: 'POST',
            url: api.profile_activate,
            data: {},
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                toast.success(ress.data.message);
                self.getData();
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    }

    render() {
        return (
            <SubLayout data={{ "title": strings.profile_information }}>
                <div className="profile">
                    <div className="main_content">
                        <label className="flex-center" htmlFor="files" style={{ justifyContent: "space-between", padding: 10 }}>
                            <div className="flex-center">
                                <div className='profile_back' style={{}}>
                                    <img className='profile_back' src={(this.state.user_data && this.state.user_data.avatar_url) ? this.state.user_data.avatar_url : Avatar1} alt=""></img>
                                </div>
                                <div style={{ marginLeft: 10 }}>
                                    <div><span>{strings.change_avatar}</span></div>
                                </div>
                            </div>
                        </label>
                        <input id="files" style={{ visibility: "hidden", display: "none" }} type="file" onChange={this.handleFileSelect} placeholder={strings.change_profile_image} />
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <div className='flex-center' style={{
                            justifyContent: "space-between", padding: 10,
                            borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
                        }}>
                            <div className='flex-center' style={{ flexDirection: "row" }}>
                                <div style={{ marginLeft: 10 }}>
                                    <span style={{ fontSize: 14 }}>{strings.username}: {(this.state.user_data && this.state.user_data.name) ? this.state.user_data.name : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex-center' style={{
                            justifyContent: "space-between", padding: 10,
                            borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
                        }}>
                            <div className='flex-center' style={{ flexDirection: "row" }}>
                                <div style={{ marginLeft: 10 }}>
                                    <span style={{ fontSize: 14 }}>{strings.email}: {(this.state.user_data && this.state.user_data.email) ? this.state.user_data.email : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex-center' style={{
                            justifyContent: "space-between", padding: 10,
                            borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
                        }}>
                            <div className='flex-center' style={{ flexDirection: "row" }}>
                                <div style={{ marginLeft: 10 }}>
                                    <span style={{ fontSize: 14 }}>{strings.id}: {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex-center' style={{
                            justifyContent: "space-between", padding: 10,
                            borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
                        }}>
                            <div className='flex-center' style={{ flexDirection: "row" }}>
                                <div style={{ marginLeft: 10 }}>
                                    <span style={{ fontSize: 14 }}>{strings.my_inviter_id}: {(this.state.user_data && this.state.user_data.invitation_code) ? this.state.user_data.invitation_code : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex-center' style={{
                            justifyContent: "space-between", padding: 10,
                            borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
                        }}>
                            <div className='flex-center' style={{ flexDirection: "row" }}>
                                <div style={{ marginLeft: 10 }}>
                                    {strings.level}: <span className={(this.state.user_data && this.state.user_data.level_data && this.state.user_data.level_data.class) ? this.state.user_data.level_data.class : ''} style={{ fontSize: 14 }}>{(this.state.user_data && this.state.user_data.level_data && this.state.user_data.level_data.name) ? this.state.user_data.level_data.name : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex-center' style={{
                            justifyContent: "space-between", padding: 10,
                            borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
                        }}>
                            <div className='flex-center' style={{ flexDirection: "row" }}>
                                <div style={{ marginLeft: 10 }}>
                                    {strings.status}: <span className={(this.state.user_data && this.state.user_data.status_data && this.state.user_data.status_data.class) ? this.state.user_data.status_data.class : ''} style={{ fontSize: 14 }}>{(this.state.user_data && this.state.user_data.status_data && this.state.user_data.status_data.name) ? this.state.user_data.status_data.name : ""}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex-center' style={{
                            justifyContent: "space-between", padding: 10
                        }}>
                            <div className='flex-center' style={{ flexDirection: "row" }}>
                                {(this.state.user_data && this.state.user_data.status) ?
                                    <>
                                        {this.state.user_data && this.state.user_data.expired_at_data && this.state.user_data.expired_at_data.expired_at ?
                                            <>
                                                {this.state.user_data && this.state.user_data.expired_at_data && this.state.user_data.expired_at_data.expired_days > 7 ?
                                                    <div className={{}} style={{ marginLeft: 10, paddingBlock: 2 }}>
                                                        <Countdown date={moment(this.state.user_data.expired_at_data.expired_at, "DD-MM-YYYY").format()} />
                                                    </div>
                                                    :
                                                    <div onClick={() => this.activeProfile()} className={'back_success'} style={{ marginLeft: 10, paddingInline: 20, paddingBlock: 2, borderRadius: 10, cursor: "pointer" }}>
                                                        <span style={{ fontSize: 14, color: "#000" }}>{"Active"}</span>
                                                    </div>
                                                }
                                            </>
                                            : null}
                                    </>
                                    :
                                    <div onClick={() => this.activeProfile()} className={'back_success'} style={{ marginLeft: 10, paddingInline: 20, paddingBlock: 2, borderRadius: 10, cursor: "pointer" }}>
                                        <span style={{ fontSize: 14, color: "#000" }}>{"Active"}</span>
                                    </div>
                                    // <div onClick={() => this.activeProfile()} className={(this.state.user_data && this.state.user_data.status) ? 'back_success' : 'back_danger'} style={{ marginLeft: 10, paddingInline: 20, paddingBlock: 2, borderRadius: 10, cursor: "pointer" }}>
                                    //     <span style={{ fontSize: 14, color: "#000" }}>{(this.state.user_data && this.state.user_data.status_data && this.state.user_data.status_data.name) ? this.state.user_data.status_data.name : "-"}</span>
                                    // </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </SubLayout >
        );
    }
}

export default ProfileInfoScreen;

import React from "react";
import { Link } from 'react-router-dom';

import Header from "../../components/Front/Header";

import first from "./../../assets/front/images/3.png";
import what from "./../../assets/front/images/what.png";
import five from "./../../assets/front/images/five.png";

import bf1 from "./../../assets/front/images/bf1.png";
import bf2 from "./../../assets/front/images/bf2.png";
import bf3 from "./../../assets/front/images/bf3.png";
import bf4 from "./../../assets/front/images/bf4.png";
import bf5 from "./../../assets/front/images/bf5.png";
import bf6 from "./../../assets/front/images/bf6.png";

class Front extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_load: false,
            location: props.location,
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <div className="front-page">
                {!this.state.is_load ?
                    <>
                        <Header />
                        <div>
                            <section id="home" className="header-area header-eight">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-12 col-12">
                                            <div className="header-content">
                                                <h1>Start your crypto journey With just a simple click</h1>
                                                <p>
                                                    Benchmark robot is a powerful crypto trading robot to help you save time, less skills, trade 24/7, automate your trading.
                                                </p>
                                                <div style={{ marginBlock: 20 }}>
                                                    <Link to={"/"} className="btn site-btn">Learn more</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-12">
                                            <div className="header-image">
                                                <img src={first} alt="#" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section id="PAGES" className="about-area about-five">

                                <div className="section-title-five">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="content">
                                                    <h6 className="site-color">What is benchmark</h6>
                                                    <h2 className="fw-bold">how It Works</h2>
                                                    <p>
                                                        Easy, effective and automate
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="about-image-five">
                                                <img src={what} alt="about" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="about-five-content">
                                                <div>
                                                    BENCHMARK is an automated cryptocurrency bot service platform that
                                                    binds with the market’s latest crypto exchange platform such as Binance,
                                                    Huobi and etc.
                                                </div>
                                                <br />
                                                <div>
                                                    In this bot service platform, it consist of low risk higher reward idea of
                                                    setting, ready for the user able to profit on the first day trading with API
                                                    connection to communicate with all of the world’s.
                                                </div>
                                                <br />
                                                <div>
                                                    BENCHMARK was create and tested by a group of experienced crypto
                                                    technical team members, who successfully fusing large data analysis and
                                                    different industrial conditions, then establishing a set of high precision
                                                    and flawless quantum strategy algorithms on the cloud server.
                                                </div>
                                                <br />
                                                <div>
                                                    BENCHMARK has been developed and rigorously tested by the our
                                                    professional risk & reward team in a real-world trading environment for
                                                    years. Using years of data and multiple market circumstances strategies,
                                                    as well as the human aspect and Bitcoin trading pattern.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section id="FEATURE" className="services-area services-eight second-eight">
                                <div className="section-title-five">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="content">
                                                    <h6 className="site-color">Benchmark Feature</h6>
                                                    <h2 className="fw-bold">Why benchmark</h2>
                                                    <p>
                                                        Experience the leading cryptocurrency trading bot
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-services">
                                                <div className="service-icon">
                                                    <img src={bf1} alt="#" />
                                                </div>
                                                <div className="service-content">
                                                    <h4>High security</h4>
                                                    <p>
                                                        Fund at your own wallet , untouchable by others
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-services">
                                                <div className="service-icon">
                                                    <img src={bf2} alt="#" />
                                                </div>
                                                <div className="service-content">
                                                    <h4>Bot serves 24/7</h4>
                                                    <p>
                                                        Bot continues working day and night, performing the best return for user
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-services">
                                                <div className="service-icon">
                                                    <img src={bf3} alt="#" />
                                                </div>
                                                <div className="service-content">
                                                    <h4>Consistant profit</h4>
                                                    <p>
                                                        Even at bear market, bot still can performing well while any situation
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-services">
                                                <div className="service-icon">
                                                    <img src={bf4} alt="#" />
                                                </div>
                                                <div className="service-content">
                                                    <h4>Easy for newbies</h4>
                                                    <p>
                                                        No experience needed or knowledges, let the bot help you to trade without emotions
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-services">
                                                <div className="service-icon">
                                                    <img src={bf5} alt="#" />
                                                </div>
                                                <div className="service-content">
                                                    <h4>Compounding portfolio</h4>
                                                    <p>
                                                        If the compound interest investment at 10% monthly investment profit, the annual profit can reach more than 300%.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div className="single-services">
                                                <div className="service-icon">
                                                    <img src={bf6} alt="#" />
                                                </div>
                                                <div className="service-content">
                                                    <h4>API bind with multi brokers</h4>
                                                    <p>
                                                        Diversify your own fund into different platform, decrease your fund risk.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section id="ROADMAP" className="about-area about-five">
                                <div className="section-title-five">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="content">
                                                    <h6 className="site-color">ROADMAP</h6>
                                                    <h2 className="fw-bold">The Timeline</h2>
                                                    <p>
                                                        Start the journey with us
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="ps-timeline-sec">
                                    <div className="container">
                                        <ol className="ps-timeline">
                                            <li>
                                                <div className="img-handler-top">
                                                    Q4 2022
                                                </div>
                                                <div className="ps-bot">
                                                    <p>Build community system Trade in multiple exchange</p>
                                                </div>
                                                <span className="ps-sp-top">
                                                    <div className="dot"></div>
                                                </span>
                                            </li>
                                            <li>
                                                <div className="img-handler-bot">
                                                    Q1 2023
                                                </div>
                                                <div className="ps-top">
                                                    <p>Copy trade system
                                                        NFT launch with great benefits
                                                    </p>
                                                </div>
                                                <span className="ps-sp-bot"><div className="dot"></div></span>
                                            </li>
                                            <li>
                                                <div className="img-handler-top">
                                                    Q3 2023
                                                </div>
                                                <div className="ps-bot">
                                                    <p>AI trade system
                                                        Staking system with great profits
                                                    </p>
                                                </div>
                                                <span className="ps-sp-top"><div className="dot"></div></span>
                                            </li>
                                            <li>
                                                <div className="img-handler-bot">
                                                    Q4 2024
                                                </div>
                                                <div className="ps-top">
                                                    <p>100,000 user on Benchmark Create cryptoprenuers in the future  </p>
                                                </div>
                                                <span className="ps-sp-bot"><div className="dot"></div></span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </section>

                            <section className="services-area services-eight second-eight">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="about-image-five">
                                                <img src={five} alt="about" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="about-five-content">
                                                <h2 className="fw-bold">Earn revenue by joining our affiliate program</h2>
                                                <br />
                                                <div>
                                                    BENCHMARK is an automated cryptocurrency bot service platform that
                                                    binds with the market’s latest crypto exchange platform such as Binance,
                                                    Huobi and etc.
                                                </div>
                                                <br />
                                                <div>
                                                    In this bot service platform, it consist of low risk higher reward idea of
                                                    setting, ready for the user able to profit on the first day trading with API
                                                    connection to communicate with all of the world’s.
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* <div className="brand-area section">
                                <div className="section-title-five">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="content">
                                                    <h2 className="fw-bold">Supported Exchange</h2>
                                                    <p>
                                                        We integrate with 10+ of the most popular exchanges including
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-3 col-6">
                                            <div className="single-client text-center">
                                                <img src="https://cdn.ayroui.com/1.0/images/client-logo/graygrids.svg" alt="Logo" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="single-client text-center">
                                                <img src="https://cdn.ayroui.com/1.0/images/client-logo/uideck.svg" alt="Logo" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="single-client text-center">
                                                <img src="https://cdn.ayroui.com/1.0/images/client-logo/ayroui.svg" alt="Logo" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="single-client text-center">
                                                <img src="https://cdn.ayroui.com/1.0/images/client-logo/lineicons.svg" alt="Logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <section id="FAQS" className="services-area services-eight second-eight"> */}
                            <section id="FAQS" className="about-area about-five">
                                <div className="section-title-five">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="content">
                                                    <h6 className="site-color">FAQS</h6>
                                                    <h2 className="fw-bold">Frequently Asked Questions</h2>
                                                    <p>
                                                        You need to know before you start the journey
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div style={{ marginTop: 20 }}>
                                                <h5 className="fw-bold site-color">Is it safe for users to hold their digital assets in exchange account?</h5>
                                                <br />
                                                <div className="text_span">
                                                    Yes, it is safe. Trading exchanges such as BINANCE or HUOBI are one of the TOP crypto-exchange
                                                    platform in the world, highly secured with rapid funds withdrawal. It is officially awarded with online
                                                    financial licenses to conduct digital assets trading. Capital accumulated for coins such as Bitcoin,
                                                    Litecoin, and Ethereum with a total market value of more than 1.5 trillion U.S. dollars.
                                                </div>
                                            </div>
                                            <div style={{ marginTop: 20 }}>
                                                <h5 className="fw-bold site-color">Where does the trading profit come from?</h5>
                                                <br />
                                                <div className="text_span">
                                                    Quantitative trading is NOT a ponzi nor a ROI scheme. It is real spot trading orders with strategy
                                                    executed in user’s exchange account. All trading prices are based on the real-time fluctuation of the
                                                    global cryptocurrency exchange trading market.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div style={{ marginTop: 20 }}>
                                                <h5 className="fw-bold site-color">What are the risks of user funds?</h5>
                                                <br />
                                                <div className="text_span">
                                                    – Users DO NOT trade with third-party account, instead with their own Crypto Exchange account
                                                    (HUOBI/ BINANCE) which is fully owned by the users with their personal KYC verification. USDT
                                                    purchased is to be deposited into user’s own wallet address.<br />
                                                    – Users keep their exchange login & wallet password themselves.<br />
                                                    – BENCHMARK requires only API Authorization to user’s exchange account for trading maneuver.<br />
                                                    – BENCHMARK will NOT be able to withdraw user’s assets by anyway possible.<br />
                                                </div>
                                            </div>
                                            <div style={{ marginTop: 20 }}>
                                                <h5 className="fw-bold site-color">Is the user's profit fixed?</h5>
                                                <br />
                                                <div className="text_span">
                                                    NO, funds are fully managed by the user’s own trading account on the exchange, it is safe and users
                                                    may trade at any day and time. Profit is based on real-time market conditions, and the estimated
                                                    APY of trades & compounding profits could reach 100%-300% with a variety of strategies.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </>
                    : null}
            </div>
        );
    }
}
export default Front;

import React from "react";
import strings from "./../../components/Language";
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import logo from "./../../assets/images/benchmark_logo.png";

import './../../assets/front/css/front.scss';
import './../../assets/front/css/custom.scss';
import './../../assets/front/css/timeline.scss';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            is_mobile_menu_show: false,
            is_menu_show: false,
        }
    }

    async componentDidMount() {
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "zh") {
            strings.setLanguage('zh');
            await localStorage.setItem("language", 'zh');
            this.setState({ lang: 'zh' });
            window.location.reload();
        }
        else if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    setWallet(data) {
        this.setState({ setWallet: data, is_wallet_show: false });
    }

    render() {
        return (
            <>
                {/* <!--====== NAVBAR NINE PART START ======--> */}

                <section className="navbar-area navbar-nine">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">
                                    <Link to={"/"} className="navbar-brand">
                                        <img src={logo} alt="Logo" width={200} />
                                    </Link>
                                    <button onClick={() => this.setState({ is_mobile_menu_show: !this.state.is_mobile_menu_show })}
                                        className={(this.state.is_mobile_menu_show) ? "navbar-toggler active" : "navbar-toggler"} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNine"
                                        aria-controls="navbarNine" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>

                                    <div className={(this.state.is_mobile_menu_show) ? "collapse navbar-collapse sub-menu-bar show" : "collapse navbar-collapse sub-menu-bar"} id="navbarNine">
                                        <ul className="navbar-nav me-auto">
                                            <li className="nav-item">
                                                <ScrollLink to="HOME" spy={true} smooth={true} duration={250} className="page-scroll active">HOME</ScrollLink>
                                            </li>
                                            <li className="nav-item">
                                                <ScrollLink to="PAGES" spy={true} smooth={true} duration={250} className="page-scroll">PAGES</ScrollLink>
                                            </li>

                                            <li className="nav-item">
                                                <ScrollLink to="FEATURE" spy={true} smooth={true} duration={250} className="page-scroll">FEATURE</ScrollLink>
                                            </li>
                                            <li className="nav-item">
                                                <ScrollLink to="ROADMAP" spy={true} smooth={true} duration={250} className="page-scroll">ROADMAP</ScrollLink>
                                            </li>
                                            <li className="nav-item">
                                                <ScrollLink to="FAQS" spy={true} smooth={true} duration={250} className="page-scroll">FAQS</ScrollLink>
                                            </li>
                                        </ul>
                                        <div style={{ marginRight: 10 }}>
                                            <Link to={"/login"} className="btn site-btn">SIGN IN</Link>
                                        </div>
                                    </div>


                                    {/* <div onClick={() => this.setState({ is_menu_show: !this.state.is_menu_show })} className="navbar-btn d-none d-lg-inline-block">
                                        <div className="menu-bar"><i className="lni lni-menu"></i></div>
                                    </div> */}
                                </nav>
                                {/* <!-- navbar --> */}
                            </div>
                        </div>
                        {/* <!-- row --> */}
                    </div>
                    {/* <!-- container --> */}
                </section>

                {/* <!--====== NAVBAR NINE PART ENDS ======--> */}

                {/* <!--====== SIDEBAR PART START ======--> */}

                <div className={(this.state.is_menu_show) ? "sidebar-left open" : "sidebar-left"}>
                    <div onClick={() => this.setState({ is_menu_show: !this.state.is_menu_show })} className="sidebar-close">
                        <div className="close"><i className="lni lni-close"></i></div>
                    </div>
                    <div className="sidebar-content">
                        <div className="sidebar-logo">
                            <Link to={"/"}><img src={logo} alt="Logo" width={200} /></Link>
                        </div>
                        {/* <!-- logo --> */}
                        <div className="sidebar-menu">
                            <h5 className="menu-title">Quick Links</h5>
                            <ul>
                                <li><Link to={"/"} >About Us</Link></li>
                                <li><Link to={"/"} >Our Team</Link></li>
                                <li><Link to={"/"} >Latest News</Link></li>
                                <li><Link to={"/"} >Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    {/* <!-- content --> */}
                </div>
                <div className="overlay-left"></div>

                {/* <!--====== SIDEBAR PART ENDS ======--> */}
            </>
        );
    }
}

export default Header;
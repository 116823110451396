import React from 'react'
import { withRouter } from 'react-router-dom'
import BackArrow from './../../assets/images/icons/back_arrow.svg';

// const LayoutAuthSub = (props) => {
class LayoutAuthSub extends React.Component {
  constructor(props) {
    super(props);
    var headerData = (this.props && this.props.data) ? this.props.data : {};
    var headerTitle = (this.props && this.props.data && this.props.data.title) ? this.props.data.title : "";
    this.state = {
      headerData: headerData,
      headerTitle: headerTitle,
    }
  }
  render() {
    const { history } = this.props
    return (
      <>
        <div id="page">
          <div className="header-bar header-fixed header-app main_header">
            <div onClick={history.goBack} className="" style={{ cursor: "pointer", marginLeft: 20 }}>
              <img src={BackArrow} alt="" width={10} />
            </div>
            <div className="header-title color-theme text-center">{(this.state.headerTitle) ? this.state.headerTitle : "..."}</div>
            <div className="" style={{ marginRight: 25 }}>
            </div>
          </div>
          <div
            className="page-content mb-0 pb-0"
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(LayoutAuthSub)

import React from 'react';
import { Link } from 'react-router-dom';
import SubLayout from '../../components/Layout/SubLayout'
import strings from '../../components/Language';
import "./profile.scss"

import ArrowForword from './../../assets/images/icons/arrow_forword.svg';

class SecurityCentreScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
		}
		this.handleChange = this.handleChange.bind(this);

	}

	handleChange(event, newValue) {
		const self = this;
		self.setState({ tabValue: newValue });
	};

	render() {
		return (
			<SubLayout data={{ "title": strings.security_centre }}>
				<div className="profile">
					<div style={{ marginTop: 10 }}>
						<Link to="/change-password" className='flex-center' style={{
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.change_password}</span>
								</div>
							</div>
							<div className='flex-center'>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</Link>
						<Link to="/transaction-password" className='flex-center' style={{
							justifyContent: "space-between", padding: 10,
							borderBottomWidth: 0.5, borderBottomColor: "#8E8E8E", borderBottomStyle: "solid"
						}}>
							<div className='flex-center' style={{ flexDirection: "row" }}>
								<div style={{ marginLeft: 10 }}>
									<span style={{ fontSize: 14 }}>{strings.transaction_password}</span>
								</div>
							</div>
							<div className='flex-center'>
								<img src={ArrowForword} alt="ArrowForword"></img>
							</div>
						</Link>
					</div>
				</div>
			</SubLayout >
		);
	}
}

export default SecurityCentreScreen;

import React, { useState } from 'react'

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserData } from './components/Util';

//  CSS
import './App.css';
import './assets/styles/bootstrap.css';
import './assets/fonts/bootstrap-icons.css';
import './assets/styles/style.css';
import './assets/styles/jquery-confirm.min.css';

//  Components
import NotFound from './components/NotFound'

// Front
import Front from './pages/Front'

//  Pages
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import Register from './pages/Register'
import RegisterSuccessful from './pages/Register/RegisterSuccessful'

import Home from './pages/Home'
import InviteLink from './pages/InviteLink'

import APIBind from './pages/APIBind'
import Binding from './pages/APIBind/binding'

import Wallet from './pages/Wallet'
import Deposit from './pages/Wallet/Deposit'
import DepositDetails from './pages/Wallet/DepositDetails'
import Withdrawal from './pages/Wallet/Withdrawal'
import WithdrawalDetails from './pages/Wallet/WithdrawalDetails'
import Transfer from './pages/Wallet/Transfer'
import TransferDetails from './pages/Wallet/TransferDetails'
import TransferGasFee from './pages/Wallet/TransferGasFee'
import TransferGasFeeDetails from './pages/Wallet/TransferGasFeeDetails'

import TradBot from './pages/TradBot'
import TradeSettings from './pages/TradBot/TradeSettings'
import MarginConfiguration from './pages/TradBot/MarginConfiguration'

import TradBot2 from './pages/TradBot/TradBot2'
import TradeBot2Settings from './pages/TradBot/TradeBot2Settings'
import MarginConfigurationBot2 from './pages/TradBot/MarginConfigurationBot2'
import OrderListBot2 from './pages/TradBot/OrderListBot2'

import Reward from './pages/Reward'
import TradingDetails from './pages/Reward/TradingDetails'
import BotRewardDetails from './pages/Reward/BotRewardDetails'
import ProfitDetails from './pages/Reward/ProfitDetails'
import TradingMonthlyReport from './pages/Reward/TradingMonthlyReport'
import BotMonthlyReport from './pages/Reward/BotMonthlyReport'
import ProfitMonthlyReport from './pages/Reward/ProfitMonthlyReport'

import Quatitative from './pages/Quatitative'
import Notifications from './pages/Notifications'

import Profile from './pages/Profile'
import ProfileInfo from './pages/Profile/ProfileInfo'
import HelpLine from './pages/Profile/HelpLine'
import SystemSetting from './pages/Profile/SystemSetting'
import SecurityCentre from './pages/Profile/SecurityCentre'
import ChangePassword from './pages/Profile/ChangePassword'
import ChangeEmail from './pages/Profile/ChangeEmail'
import TransactionPassword from './pages/Profile/TransactionPassword'
import DeleteAccount from './pages/Profile/DeleteAccount'

import MemberCenter from './pages/MemberCenter'

import MyPolicySetting from './pages/MyPolicySetting'

import Team from './pages/Team'

import Orders from './pages/Orders'
import OrdersB from './pages/Orders/OrderB'

import WithdrawAddress from './pages/WithdrawAddress'
import AddWallet from './pages/WithdrawAddress/AddWallet'
import Bank from './pages/WithdrawAddress/Bank'
import AddBank from './pages/WithdrawAddress/AddBank'

import Community from './pages/Community'

import Chart from './pages/Chart'
import ChartDetails from './pages/Chart/ChartDetails'

import Announcement from './pages/Announcement'
import AnnouncementDetail from './pages/Announcement/AnnouncementDetail'

import GasHistory from './pages/Wallet/GasHistory'
import Covert from './pages/Wallet/Covert'


export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          // <Component {...props} />
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  )
}

export const AuthRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to={{ pathname: '/home' }} />
          // <Component {...props} />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

const App = (props) => {

  const [forceRefresh] = useState(false)

  var userData = getUserData();
  // const isAuthenticated = false;
  // const isAuthenticated = true;
  const isAuthenticated = userData.status ?? false;

  return (
    <>
      <BrowserRouter
        basename={process.env.REACT_APP_BASE_PATH}
        forceRefresh={forceRefresh}
      >
        <Switch>
          <AuthRoute
            path="/login"
            component={Login}
            isAuthenticated={isAuthenticated}
          />
          <AuthRoute
            path="/forgot-password"
            component={ForgotPassword}
            isAuthenticated={isAuthenticated}
          />
          <AuthRoute
            path="/register"
            component={Register}
            isAuthenticated={isAuthenticated}
          />
          <AuthRoute
            path="/register-successful"
            component={RegisterSuccessful}
            isAuthenticated={isAuthenticated}
          />
          <Route exact path="/" component={isAuthenticated ? Home : Front} />
          <PrivateRoute
            path="/home"
            component={Home}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/quatitative"
            component={Quatitative}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/notifications"
            component={Notifications}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            path="/profile"
            component={Profile}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/profile-information"
            component={ProfileInfo}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/helpLine"
            component={HelpLine}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/system-setting"
            component={SystemSetting}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/security-centre"
            component={SecurityCentre}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/change-password"
            component={ChangePassword}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/change-email"
            component={ChangeEmail}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/transaction-password"
            component={TransactionPassword}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/delete-account"
            component={DeleteAccount}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            path="/my-policy-setting"
            component={MyPolicySetting}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            path="/apibind"
            component={APIBind}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/binding/:id"
            component={Binding}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/binding"
            component={Binding}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/invite-link"
            component={InviteLink}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            path="/wallet"
            component={Wallet}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/deposit"
            component={Deposit}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/withdrawal"
            component={Withdrawal}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/transfer"
            component={Transfer}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/transfer-gas-fee"
            component={TransferGasFee}
            isAuthenticated={isAuthenticated}
          />

          <PrivateRoute
            path="/trad-Bot/:id"
            component={TradBot}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/trade-settings/:id"
            component={TradeSettings}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/margin-configuration/:id"
            component={MarginConfiguration}
            isAuthenticated={isAuthenticated}
          />
          
          <PrivateRoute
            path="/trad-bot-2/:id"
            component={TradBot2}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/trade-bot-2-settings/:id"
            component={TradeBot2Settings}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/bot-2-margin-configuration/:id"
            component={MarginConfigurationBot2}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/bot-2-order-list/:id"
            component={OrderListBot2}
            isAuthenticated={isAuthenticated}
          />
          
          <PrivateRoute
            path="/reward"
            component={Reward}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/trading-details"
            component={TradingDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/trading-monthly-report"
            component={TradingMonthlyReport}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/bot-reward-details"
            component={BotRewardDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/bot-monthly-report"
            component={BotMonthlyReport}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/profit-details"
            component={ProfitDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/profit-monthly-report"
            component={ProfitMonthlyReport}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/team"
            component={Team}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/withdraw-address"
            component={WithdrawAddress}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/add-wallet"
            component={AddWallet}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/bank"
            component={Bank}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/add-bank"
            component={AddBank}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/withdrawal-details"
            component={WithdrawalDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/deposit-details"
            component={DepositDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/transfer-details"
            component={TransferDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/transfer-gas-fee-details"
            component={TransferGasFeeDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/orders"
            component={Orders}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/orders-b"
            component={OrdersB}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/member-center"
            component={MemberCenter}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/community"
            component={Community}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/chart"
            component={Chart}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/ChartDetails/:pair_name"
            component={ChartDetails}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/announcement"
            component={Announcement}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/announcement-detail/:id"
            component={AnnouncementDetail}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/gas-history"
            component={GasHistory}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path="/covert"
            component={Covert}
            isAuthenticated={isAuthenticated}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export default App;

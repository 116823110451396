import React from 'react';
import SubLayout from '../../components/Layout/SubLayout';
import strings from "../../components/Language";

import "./tradbot.scss";

import { Form, Input } from 'antd';
import Button from '@mui/material/Button';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData, ordinal_suffix_of } from './../../components/Util';
import _, { unset } from 'lodash';

class MarginConfigurationScreen extends React.Component {
    constructor(props) {
        super(props);
        var params_id = this.props.match.params.id;
        this.state = {
            params_id: params_id,
            access_token: "",
            data: [],
            margin_call_limit_value: 7,
            margin_call_limit: _.times(7),
            margin_call: [],
            margin_call_drop: [],
            multiple_buy_in_ratio: [],
            is_load: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            });
        }
    }

    getData() {
        var self = this;
        if (self.state.access_token) {

            axios({
                method: 'GET',
                url: api.bot + this.state.params_id + "/detail",
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {

                    self.setState({
                        data: ress.data.data,
                        margin_call: (ress.data.data && ress.data.data.margin_call) ? ress.data.data.margin_call : [],
                        margin_call_limit_value: (ress.data.data && ress.data.data.margin_call_limit) ? ress.data.data.margin_call_limit : 7,
                        margin_call_limit: (ress.data.data && ress.data.data.margin_call_limit) ? _.times(ress.data.data.margin_call_limit) : _.times(7),
                        is_load: true,
                    });

                    if ((ress.data.data && ress.data.data.margin_call)) {
                        var margin_call_drop = [];
                        var multiple_buy_in_ratio = [];
                        _.forEach(ress.data.data.margin_call, function (value, key) {
                            margin_call_drop[key] = value.margin_call_drop;
                            multiple_buy_in_ratio[key] = value.multiple_buy_in_ratio;
                        });
                        self.setState({
                            margin_call_drop: margin_call_drop,
                            multiple_buy_in_ratio: multiple_buy_in_ratio
                        });
                    }
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {

                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    storeMarginCallDropData(e, data, i) {
        var margin_call_drop = this.state.margin_call_drop;
        margin_call_drop[i] = e;
        this.setState({ margin_call_drop: margin_call_drop });
    }

    storeMultipleBuyRatioData(e, data, i) {
        var multiple_buy_in_ratio = this.state.multiple_buy_in_ratio;
        multiple_buy_in_ratio[i] = e;
        this.setState({ multiple_buy_in_ratio: multiple_buy_in_ratio });
    }

    onFinish(values) {
        var self = this;
        unset(values['md']);
        values.margin_call_limit = self.state.margin_call_limit_value;
        values.margin_call_drop = _.pickBy(self.state.margin_call_drop, _.identity);
        values.multiple_buy_in_ratio = _.pickBy(self.state.multiple_buy_in_ratio, _.identity);
        axios({
            method: 'POST',
            url: api.bot + this.state.params_id + "/margin-configuration",
            data: values,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {

            if (ress.data && ress.data.error === 200) {
                toast.success(ress.data.message);
                self.props.history.replace({ pathname: `/trade-settings/${self.state.params_id}` })
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    render() {

        return (
            <SubLayout data={{ "title": strings.margin_configuration }}>
                <Form
                    name="sign-in"
                    style={{ marginInline: 10, marginTop: 20 }}
                    onFinish={(data) => this.onFinish(data)}
                >
                    {this.state.is_load ?
                        <>
                            <div className='flex-center' style={{ flexDirection: "row", alignItems: "center" }}>
                                <div className='form-custom form-label form-icon col-6' style={{ paddingRight: 5 }} >
                                    {strings.Margin_call_Drop} (%)
                                </div>
                                <div className='form-custom form-label form-icon col-6' style={{ paddingRight: 5 }} >
                                    {strings.Multiple_Of_Times}
                                </div>
                            </div>
                            {this.state.margin_call_limit.map((item, i) =>
                                <div key={i + 101} style={{ marginBottom: 10 }}>
                                    <label className="">{ordinal_suffix_of(i + 1)} Call</label>
                                    <div className='flex-center' style={{ flexDirection: "row", alignItems: "center" }}>
                                        <div className='form-custom form-label form-icon col-6' style={{ paddingRight: 5 }} >
                                            <Form.Item
                                                name={`md[${i}]`}
                                            >
                                                <Input
                                                    className="form-control rounded-xs"
                                                    onChange={e => this.storeMarginCallDropData(e.target.value, this.state.margin_call[i], i)}
                                                    defaultValue={(this.state.margin_call[i] && this.state.margin_call[i].margin_call_drop) ? this.state.margin_call[i].margin_call_drop : 0}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className='form-custom form-label form-icon col-6' style={{ paddingLeft: 5 }}>
                                            <Form.Item
                                                name={`mr[${i}]`}
                                            >
                                                <Input
                                                    className="form-control rounded-xs"
                                                    onChange={e => this.storeMultipleBuyRatioData(e.target.value, this.state.margin_call[i], i)}
                                                    defaultValue={(this.state.margin_call[i] && this.state.margin_call[i].multiple_buy_in_ratio) ? this.state.margin_call[i].multiple_buy_in_ratio : 0}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        : null}
                    <Form.Item>
                        <Button type="primary" className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full '
                            style={{ fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10 }}>
                            {strings.confirm}
                        </Button>
                    </Form.Item>
                </Form>
            </SubLayout >
        );
    }
}

export default MarginConfigurationScreen;
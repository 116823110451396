import React from 'react'
import SubLayout from '../../components/Layout/SubLayout'
import strings from "../../components/Language";
import _ from 'lodash';

//  CSS
import './../../assets/styles/style.scss';
import ScanQR from './../../assets/images/icons/scan_qr.svg';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from './../../components/config/api.js';
import { getToken, storeUserData, getUserData } from './../../components/Util';

import Modal from '@mui/material/Modal';
import QrReader from 'react-qr-scanner'

class WithdrawalScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            access_token: "",
            bank_id: 1,
            wallet_address: "",
            amount: '',
            user_data: {},
            isModal: false,
            verification_code: '',
            otp_code: '',
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                var rowData = getUserData();
                if (rowData.data) {
                    this.setState({
                        user_data: rowData.data
                    });
                }
            });
        }
    }

    sendCode() {
        var self = this;
        if (self.state.access_token) {
            axios({
                method: 'POST',
                url: api.withdraw_send_code,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    self.setState({ otp_code: ress.data.data.otp_code });
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    sendData() {
        var self = this;
        if (self.state.access_token) {

            if (!self.state.wallet_address) {
                toast.error(strings.the_address_field_is_required);
                return false;
            }
            if (!self.state.amount) {
                toast.error(strings.the_amount_field_is_required);
                return false;
            }
            if (!self.state.verification_code) {
                toast.error(strings.verification_code_required);
                return false;
            }
            if (self.state.otp_code != self.state.verification_code) {
                toast.error(strings.verification_code_not_valid);
                return false;
            }

            var json = {
                // bank_id: self.state.bank_id,
                wallet_address: self.state.wallet_address,
                amount: self.state.amount,
                verification_code: self.state.verification_code,
            }

            self.setState({ loading: true });
            axios({
                method: 'POST',
                url: api.withdraw_store,
                data: json,
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {
                    toast.success(ress.data.message);
                    self.props.history.replace({ pathname: `/withdrawal-details` })
                } else {
                    if (ress.data.validation && ress.data.validation.length !== 0) {
                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
                self.setState({ loading: false });
            }).catch(function (e) {
                //toast.error(e.message);
                self.setState({ loading: false });
            });
        }
    };

    closeModal() {
        var self = this;
        self.setState({
            isModal: false,
        });
    }

    render() {

        return (
            <>
                <SubLayout ref={this.wrapper} data={{ "title": strings.withdrawal }}>
                    <div className='offset-md-4 col-md-4' style={{ marginTop: 20, paddingInline: 5 }}>
                        <div className="text-right">
                            <Link to={"/withdrawal-details"} style={{ marginRight: 10 }} >{strings.Withdraw_details}</Link>
                        </div>
                        <div className="flex-center">
                            {strings.chain_name}
                            <span style={{ borderWidth: 1, borderStyle: "solid", borderColor: "#446CE3", paddingBlock: 5, paddingInline: 10, marginInline: 10, borderRadius: 5 }}>TRC 20</span>
                        </div>
                        <div className="list_item" style={{ padding: 0, paddingBlock: 10, borderRadius: 10 }}>
                            <div className='row' style={{ margin: 10, color: "#8E8E8E" }}>
                                <div className='col-12' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.withdrawal_address}</label>
                                </div>
                                <div className='col-12 flex-center' style={{
                                    justifyContent: "space-between", background: "#07051F",
                                    padding: 10, marginTop: 10, borderRadius: 10
                                }}>
                                    <div className='text_box_main'>
                                        <input
                                            name='wallet_address'
                                            onChange={e => this.setState({ wallet_address: e.target.value })}
                                            value={this.state.wallet_address}
                                            className="form-control rounded-xs text_box"
                                            placeholder={strings.withdrawal_address_enter}
                                        />
                                    </div>
                                    <div style={{ marginInline: 10, fontSize: 14 }}>
                                        <img onClick={() => this.setState({ isModal: true })} src={ScanQR} alt="" style={{ marginRight: 10, cursor: "pointer" }} />
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ margin: 10, color: "#8E8E8E" }}>
                                <div className='col-12' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.withdrawal_quantity}</label>
                                </div>
                                <div className='col-12 flex-center' style={{
                                    justifyContent: "space-between", background: "#07051F",
                                    padding: 10, marginTop: 10, borderRadius: 10
                                }}>
                                    <div className='text_box_main'>
                                        <input
                                            name='amount'
                                            onChange={e => this.setState({ amount: e.target.value })}
                                            value={this.state.amount}
                                            className="form-control rounded-xs text_box"
                                            placeholder={strings.minimum_withdraw_amount}
                                        />
                                    </div>
                                    <div onClick={() => this.setState({ amount: (this.state.user_data && this.state.user_data.credit && this.state.user_data.credit.usdt) ? parseFloat(this.state.user_data.credit.usdt).toFixed(5) : 0.00 })} style={{ marginInline: 10, color: "#446CE3", fontSize: 14, cursor: "pointer" }}>
                                        {strings.All}
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: 10, padding: 10, color: "#8E8E8E" }}>{strings.Available}: {(this.state.user_data && this.state.user_data.credit && this.state.user_data.credit.usdt) ? parseFloat(this.state.user_data.credit.usdt).toFixed(5) : "0.00"} {strings.usdt}</div>

                            <div className='row' style={{ margin: 10, color: "#8E8E8E" }}>
                                <div className='col-12' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.verification_code}</label>
                                </div>
                                <div className='col-12 flex-center' style={{
                                    justifyContent: "space-between", background: "#07051F",
                                    padding: 10, marginTop: 10, borderRadius: 10
                                }}>
                                    <div className='col-8 text_box_main_otp' style={{ color: "#8E8E8E", fontSize: 14 }}>
                                        <input
                                            type="text"
                                            name='verification_code'
                                            onChange={e => this.setState({ verification_code: e.target.value })}
                                            value={this.state.verification_code}
                                            className="form-control rounded-xs text_box"
                                            style={{ background: "transparent", borderColor: "transparent" }}
                                            placeholder={strings.verification_code_enter}
                                        />
                                    </div>
                                    <Button
                                        loading={this.state.loading_code}
                                        variant="outlined"
                                        onClick={(data) => this.sendCode()}
                                        style={{
                                            opacity: this.state.loading_code ? 0.7 : 1, padding: 5, borderWidth: 1, borderColor: "#446CE3", borderRadius: 5,
                                            background: "transparent", color: "#FFF", marginLeft: 10
                                        }}>
                                        {this.state.loading_code ? strings.loading : strings.send_code}
                                    </Button>
                                </div>
                            </div>

                            <div className='flex-center' style={{ justifyContent: "space-between", margin: 10, padding: 10, }}>
                                <div style={{ fontSize: 14 }}>{strings.Transaction_Fees}</div>
                                <div style={{ fontSize: 14 }}>2    {strings.usdt}</div>
                            </div>
                            <div className='flex-center' style={{ justifyContent: "space-between", margin: 10, padding: 10, }}>
                                <div style={{ fontSize: 14 }}>{strings.receive_amount}</div>
                                <div style={{ fontSize: 14 }}>0</div>
                            </div>
                        </div>
                        <div className='border_'></div>

                        <div style={{ margin: 10, marginBottom: 20 }}>
                            {strings.risk_disclaimer}
                            <div style={{ marginTop: 10, color: "#8E8E8E" }}>
                                <div>{strings.withdrawal_risk_disclaimer_point_1}</div>
                                <div>{strings.withdrawal_risk_disclaimer_point_2}</div>
                                <div>{strings.withdrawal_risk_disclaimer_point_3}</div>
                            </div>
                        </div>

                        <div className="col text-center" style={{ margin: 10, marginTop: 20 }}>
                            <Button
                                onClick={() => this.sendData()}
                                className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full'
                                style={{ fontSize: 14, paddingBlock: 10, background: '#446CE3', color: "#FFF", width: "100%", borderRadius: 10, cursor: "pointer", opacity: (this.state.loading) ? 0.7 : 1, }}
                                loading={this.state.loading}
                            >
                                {(this.state.loading) ? strings.loading : strings.confirm_withdrawal}
                            </Button>
                        </div>

                    </div>
                </SubLayout>

                <Modal
                    open={this.state.isModal ?? false}
                    className="itemModal"
                    onClose={this.closeModal}
                    style={{ overflow: 'scroll' }}
                >
                    <>
                        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                            <div className="modal-content" style={{ background: "#616162" }}>
                                <div className="modal-header" style={{ width: "100%" }}>
                                    <span style={{ color: "#FFF" }}>{strings.Scan_QR_Code}</span>
                                    <button onClick={() => this.closeModal()}
                                        type="button" className="close" data-dismiss="modal" aria-label="Close"
                                        style={{ background: "transparent", borderWidth: 0 }}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <QrReader
                                            style={{
                                                width: '100%',
                                            }}
                                            delay={300}
                                            facingMode="rear"
                                            onScan={(result, error) => {
                                                if (!!result) {
                                                    this.setState({
                                                        wallet_address: result.text,
                                                        isModal: false,
                                                    });
                                                }

                                                if (!!error) { }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            </>
        );
    }
}

export default WithdrawalScreen;
